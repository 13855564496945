var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "selected_visibility_policy";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <li class=\"visibility_policy_option "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":18,"column":54},"end":{"line":18,"column":108}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":143}}})) != null ? stack1 : "")
    + "\"\n              data-visibility-policy=\""
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"all_visibility_policies"), "UNMUTED", {"start":{"line":19,"column":41},"end":{"line":19,"column":72}} ), depth0))
    + "\">\n                <a tabindex=\"0\" class=\"icon_and_text\">\n                    <i class=\"zulip-icon zulip-icon-unmute-new\" aria-hidden=\"true\"></i>\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":20},"end":{"line":22,"column":34}}}))
    + "\n                </a>\n            </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list visibility_policy_popover hidden-for-spectators\">\n    <div class=\"visibility_policy_popover_container\">\n        <li class=\"visibility_policy_option "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":3,"column":102}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":44},"end":{"line":3,"column":137}}})) != null ? stack1 : "")
    + "\"\n          data-visibility-policy=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"all_visibility_policies"), "MUTED", {"start":{"line":4,"column":37},"end":{"line":4,"column":66}} ), depth0))
    + "\">\n            <a tabindex=\"0\" class=\"icon_and_text\">\n                <i class=\"zulip-icon zulip-icon-mute-new\" aria-hidden=\"true\"></i>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":7,"column":28}}}))
    + "\n            </a>\n        </li>\n        <li class=\"visibility_policy_option "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":10,"column":50},"end":{"line":10,"column":104}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":44},"end":{"line":10,"column":139}}})) != null ? stack1 : "")
    + "\"\n          data-visibility-policy=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"all_visibility_policies"), "INHERIT", {"start":{"line":11,"column":37},"end":{"line":11,"column":68}} ), depth0))
    + "\">\n            <a tabindex=\"0\" class=\"icon_and_text\">\n                <i class=\"zulip-icon zulip-icon-inherit\" aria-hidden=\"true\"></i>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":31}}}))
    + "\n            </a>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"stream_muted"),lookupProperty(depth0,"topic_unmuted"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":45}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        <li class=\"visibility_policy_option "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":26,"column":50},"end":{"line":26,"column":105}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":44},"end":{"line":26,"column":140}}})) != null ? stack1 : "")
    + "\"\n          data-visibility-policy=\""
    + alias4(alias3(alias2(lookupProperty(depth0,"all_visibility_policies"), "FOLLOWED", {"start":{"line":27,"column":37},"end":{"line":27,"column":69}} ), depth0))
    + "\">\n            <a tabindex=\"0\" class=\"icon_and_text\">\n                <i class=\"zulip-icon zulip-icon-follow\" aria-hidden=\"true\"></i>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":30,"column":30}}}))
    + "\n            </a>\n        </li>\n    </div>\n</ul>\n";
},"useData":true});