var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "selected-tab";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"tab-option tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"UNMUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":17,"column":65},"end":{"line":17,"column":119}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":59},"end":{"line":17,"column":140}}})) != null ? stack1 : "")
    + "\" data-visibility-policy=\""
    + alias2(container.lambda(container.strict(lookupProperty(depth0,"all_visibility_policies"), "UNMUTED", {"start":{"line":17,"column":168},"end":{"line":17,"column":199}} ), depth0))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":223},"end":{"line":17,"column":238}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unmute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":252},"end":{"line":17,"column":267}}}))
    + "\">\n                    <i class=\"zulip-icon zulip-icon-unmute-new\"></i>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"topic-menu-item hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-unstar-all-in-topic\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":31,"column":87},"end":{"line":31,"column":96}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":31,"column":84},"end":{"line":31,"column":99}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":31,"column":121},"end":{"line":31,"column":131}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":31,"column":118},"end":{"line":31,"column":134}}}) : helper)))
    + "\">\n            <i class=\"zulip-icon zulip-icon-star\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Unstar all messages in topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":12},"end":{"line":33,"column":49}}}))
    + "\n        </a>\n    </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"topic-menu-item hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-mark-topic-read\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":40,"column":83},"end":{"line":40,"column":92}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":40,"column":80},"end":{"line":40,"column":95}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":40,"column":117},"end":{"line":40,"column":127}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":40,"column":114},"end":{"line":40,"column":130}}}) : helper)))
    + "\">\n            <i class=\"fa fa-book\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":12},"end":{"line":42,"column":45}}}))
    + "\n        </a>\n    </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"topic-menu-item hidden-for-spectators\">\n        <a tabindex=\"0\" class=\"sidebar-popover-mark-topic-unread\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":47,"column":85},"end":{"line":47,"column":94}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":47,"column":82},"end":{"line":47,"column":97}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":47,"column":119},"end":{"line":47,"column":129}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":47,"column":116},"end":{"line":47,"column":132}}}) : helper)))
    + "\">\n            <i class=\"fa fa-book\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Mark all messages as unread",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":49,"column":47}}}))
    + "\n        </a>\n    </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <hr />\n\n    <li class=\"topic-menu-item\">\n        <a tabindex=\"0\" class=\"sidebar-popover-move-topic-messages\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":65,"column":87},"end":{"line":65,"column":96}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":65,"column":84},"end":{"line":65,"column":99}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":65,"column":121},"end":{"line":65,"column":131}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":65,"column":118},"end":{"line":65,"column":134}}}) : helper)))
    + "\">\n            <i class=\"fa fa-arrows\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Move topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":67,"column":30}}}))
    + "\n        </a>\n    </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"can_rename_topic"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":79,"column":4}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <hr />\n\n    <li class=\"topic-menu-item\">\n        <a tabindex=\"0\" class=\"sidebar-popover-rename-topic-messages\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":74,"column":89},"end":{"line":74,"column":98}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":74,"column":86},"end":{"line":74,"column":101}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":74,"column":123},"end":{"line":74,"column":133}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":74,"column":120},"end":{"line":74,"column":136}}}) : helper)))
    + "\">\n            <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Rename topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":76,"column":12},"end":{"line":76,"column":32}}}))
    + "\n        </a>\n    </li>\n    ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"topic-menu-item\">\n        <a tabindex=\"0\" class=\"sidebar-popover-toggle-resolved\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":83,"column":83},"end":{"line":83,"column":92}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":83,"column":80},"end":{"line":83,"column":95}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":83,"column":117},"end":{"line":83,"column":127}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":83,"column":114},"end":{"line":83,"column":130}}}) : helper)))
    + "\">\n            <i class=\"fa fa-check\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"topic_is_resolved"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":89,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as unresolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":86,"column":38}}}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark as resolved",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":12},"end":{"line":88,"column":36}}}))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"topic-menu-item\">\n        <a tabindex=\"0\" class=\"sidebar-popover-delete-topic-messages\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":96,"column":89},"end":{"line":96,"column":98}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":96,"column":86},"end":{"line":96,"column":101}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":96,"column":123},"end":{"line":96,"column":133}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":96,"column":120},"end":{"line":96,"column":136}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":12},"end":{"line":98,"column":32}}}))
    + "\n        </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list topics_popover\">\n    <li class=\"topic-menu-item\">\n        <div class=\"topic-name\">"
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":3,"column":34},"end":{"line":3,"column":44}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":3,"column":32},"end":{"line":3,"column":46}}}) : helper)))
    + "</div>\n    </li>\n\n    <hr />\n\n    <li class=\"topic-menu-item hidden-for-spectators\">\n        <div class=\"tabs-container\">\n            <div class=\"tab-option tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"MUTED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":10,"column":61},"end":{"line":10,"column":113}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":55},"end":{"line":10,"column":134}}})) != null ? stack1 : "")
    + "\" data-visibility-policy=\""
    + alias4(alias5(alias1(lookupProperty(depth0,"all_visibility_policies"), "MUTED", {"start":{"line":10,"column":162},"end":{"line":10,"column":191}} ), depth0))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":215},"end":{"line":10,"column":228}}}))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Mute",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":242},"end":{"line":10,"column":255}}}))
    + "\">\n                <i class=\"zulip-icon zulip-icon-mute-new\"></i>\n            </div>\n            <div class=\"tab-option tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"INHERIT"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":13,"column":61},"end":{"line":13,"column":115}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":55},"end":{"line":13,"column":136}}})) != null ? stack1 : "")
    + "\" data-visibility-policy=\""
    + alias4(alias5(alias1(lookupProperty(depth0,"all_visibility_policies"), "INHERIT", {"start":{"line":13,"column":164},"end":{"line":13,"column":195}} ), depth0))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":219},"end":{"line":13,"column":235}}}))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Default",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":249},"end":{"line":13,"column":265}}}))
    + "\">\n                <i class=\"zulip-icon zulip-icon-inherit\"></i>\n            </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"or").call(alias2,lookupProperty(depth0,"stream_muted"),lookupProperty(depth0,"topic_unmuted"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":16,"column":18},"end":{"line":16,"column":49}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":20,"column":19}}})) != null ? stack1 : "")
    + "            <div class=\"tab-option tippy-zulip-tooltip "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"eq").call(alias2,lookupProperty(depth0,"visibility_policy"),lookupProperty(lookupProperty(depth0,"all_visibility_policies"),"FOLLOWED"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":21,"column":61},"end":{"line":21,"column":116}}}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":55},"end":{"line":21,"column":137}}})) != null ? stack1 : "")
    + "\" data-visibility-policy=\""
    + alias4(alias5(alias1(lookupProperty(depth0,"all_visibility_policies"), "FOLLOWED", {"start":{"line":21,"column":165},"end":{"line":21,"column":197}} ), depth0))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":221},"end":{"line":21,"column":236}}}))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Follow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":250},"end":{"line":21,"column":265}}}))
    + "\">\n                <i class=\"zulip-icon zulip-icon-follow\"></i>\n            </div>\n        </div>\n    </li>\n\n    <hr class=\"hidden-for-spectators\" />\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"has_starred_messages"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":36,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"has_unread_messages"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "")
    + "\n    <li class=\"topic-menu-item\">\n        <a tabindex=\"0\" class=\"sidebar-popover-copy-link-to-topic\" data-stream-id=\""
    + alias4((((helper = lookupProperty(helpers,"stream_id") || alias1(depth0, "stream_id", {"start":{"line":55,"column":86},"end":{"line":55,"column":95}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"stream_id","hash":{},"data":data,"loc":{"start":{"line":55,"column":83},"end":{"line":55,"column":98}}}) : helper)))
    + "\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias1(depth0, "topic_name", {"start":{"line":55,"column":120},"end":{"line":55,"column":130}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":55,"column":117},"end":{"line":55,"column":133}}}) : helper)))
    + "\" data-clipboard-text=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias1(depth0, "url", {"start":{"line":55,"column":159},"end":{"line":55,"column":162}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":55,"column":156},"end":{"line":55,"column":165}}}) : helper)))
    + "\">\n            <i class=\"fa fa-link\" aria-hidden=\"true\"></i>\n            "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Copy link to topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":12},"end":{"line":57,"column":38}}}))
    + "\n        </a>\n    </li>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"can_move_topic"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":61,"column":4},"end":{"line":79,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"can_rename_topic"),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":92,"column":11}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_realm_admin"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":94,"column":4},"end":{"line":101,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});