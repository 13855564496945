var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group email_change_container\">\n            <label for=\"email\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":31},"end":{"line":11,"column":45}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"email\" class=\"modal_text_input\" value=\""
    + alias1(container.lambda(container.strict(depth0, "email", {"start":{"line":12,"column":98},"end":{"line":12,"column":103}} ), depth0))
    + "\" readonly/>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"owner_is_only_user_in_organization"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":31,"column":101}}})) != null ? stack1 : "")
    + ">\n            <button class=\"button rounded btn-danger deactivate_user_button\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"owner_is_only_user_in_organization"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":77},"end":{"line":32,"column":145}}})) != null ? stack1 : "")
    + ">\n                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Deactivate user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":16},"end":{"line":33,"column":40}}}))
    + "\n            </button>\n        </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"deactivate_user_button_tooltip\"";
},"8":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span>\n            <button class=\"button rounded reactivate_user_button\">\n                "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Reactivate user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":16},"end":{"line":39,"column":40}}}))
    + "\n            </button>\n        </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"edit-user-form\" data-user-id=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":1,"column":41},"end":{"line":1,"column":48}} ), depth0))
    + "\">\n    <form class=\"name-setting\">\n        <div class=\"alert\" id=\"edit-user-form-error\"></div>\n        <input type=\"hidden\" name=\"is_full_name\" value=\"true\" />\n        <div class=\"input-group name_change_container\">\n            <label for=\"edit_user_full_name\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":45},"end":{"line":6,"column":58}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"full_name\" id=\"edit_user_full_name\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "full_name", {"start":{"line":7,"column":127},"end":{"line":7,"column":136}} ), depth0))
    + "\" />\n        </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"email"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"input-group user_id_container\">\n            <label for=\"user_id\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"User ID",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":33},"end":{"line":16,"column":49}}}))
    + "</label>\n            <input type=\"text\" autocomplete=\"off\" name=\"user_id\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "user_id", {"start":{"line":17,"column":100},"end":{"line":17,"column":107}} ), depth0))
    + "\" readonly/>\n        </div>\n        <div class=\"input-group\">\n            <label class=\"input-label\" for=\"user-role-select\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"User role",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":62},"end":{"line":20,"column":80}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/roles-and-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"user-role-select\" class=\"bootstrap-focus-style modal_select\" id=\"user-role-select\" data-setting-widget-type=\"number\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"disable_role_dropdown"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":143},"end":{"line":23,"column":187}}})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"user_role_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"custom-profile-field-form\"></div>\n    </form>\n    <div class=\"input-group new-style\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true});