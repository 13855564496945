var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"choice-row\" data-value=\""
    + alias1(container.lambda(container.strict(depth0, "value", {"start":{"line":1,"column":38},"end":{"line":1,"column":43}} ), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("../dropdown_widget.hbs"),depth0,{"name":"../dropdown_widget","hash":{"default_text":lookupProperty(helpers,"t").call(alias2,"Select stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":80},"end":{"line":2,"column":99}}}),"widget_name":lookupProperty(depth0,"stream_dropdown_widget_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <button type=\"button\" class=\"button rounded small delete-choice\" title=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":76},"end":{"line":3,"column":91}}}))
    + "\">\n        <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n    </button>\n</div>\n";
},"usePartial":true,"useData":true});