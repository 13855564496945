var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled\n      ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"notification_setting"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":6},"end":{"line":4,"column":62}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "sub_notification_setting";
},"6":function(container,depth0,helpers,partials,data) {
    return "class=\"tippy-zulip-tooltip\" data-tooltip-template-id=\"mobile-push-notification-tooltip-template\"";
},"8":function(container,depth0,helpers,partials,data) {
    return "checked";
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/mute-a-stream"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"setting_name"),"push_notifications",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":20,"column":14},"end":{"line":20,"column":52}}}),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":4}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/mobile-notifications#enabling-push-notifications-for-self-hosted-servers"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"sub_setting_checkbox\">\n    <div id=\"sub_"
    + alias3(alias2(alias1(depth0, "setting_name", {"start":{"line":3,"column":19},"end":{"line":3,"column":31}} ), depth0))
    + "_setting\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"disabled_realm_setting"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":3,"column":50},"end":{"line":4,"column":69}}})) != null ? stack1 : "")
    + " new-style\">\n        <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"and").call(alias4,lookupProperty(depth0,"disabled_realm_setting"),lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"setting_name"),"push_notifications",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":5,"column":48},"end":{"line":5,"column":86}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":5,"column":20},"end":{"line":5,"column":87}}}),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":192}}})) != null ? stack1 : "")
    + ">\n            <label class=\"checkbox\">\n                <input id=\""
    + alias3(alias2(alias1(depth0, "setting_name", {"start":{"line":7,"column":29},"end":{"line":7,"column":41}} ), depth0))
    + "_"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":7,"column":46},"end":{"line":7,"column":55}} ), depth0))
    + "\" name=\""
    + alias3(alias2(alias1(depth0, "setting_name", {"start":{"line":7,"column":67},"end":{"line":7,"column":79}} ), depth0))
    + "\"\n                  class=\"sub_setting_control\" type=\"checkbox\"\n                  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_checked"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":50}}})) != null ? stack1 : "")
    + "\n                  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_disabled"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":18},"end":{"line":10,"column":63}}})) != null ? stack1 : "")
    + " />\n                <span></span>\n            </label>\n            <label class=\"inline\" for=\""
    + alias3(alias2(alias1(depth0, "setting_name", {"start":{"line":13,"column":41},"end":{"line":13,"column":53}} ), depth0))
    + "_"
    + alias3(alias2(alias1(depth0, "stream_id", {"start":{"line":13,"column":58},"end":{"line":13,"column":67}} ), depth0))
    + "\">\n                "
    + alias3(alias2(alias1(depth0, "label", {"start":{"line":14,"column":18},"end":{"line":14,"column":23}} ), depth0))
    + "\n            </label>\n        </span>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"setting_name"),"is_muted",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":18,"column":10},"end":{"line":18,"column":38}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});