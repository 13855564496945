var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":92},"end":{"line":10,"column":113}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button compose_upload_file zulip-icon zulip-icon-attachment notdisplayed\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Upload files",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":134},"end":{"line":11,"column":155}}}))
    + "\" tabindex=0></a>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"compose_control_button_container preview_mode_disabled\" data-tooltip-template-id=\"add-poll-tooltip\" data-tippy-maxWidth=\"none\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-poll add-poll\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add poll",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":104},"end":{"line":29,"column":121}}}))
    + "\" tabindex=0></a>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"compose-control-buttons-container order-1\">\n    <input type=\"file\" class=\"file_input notvisible\" multiple />\n    <div class=\"compose_control_button_container\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":70},"end":{"line":3,"column":86}}}))
    + "\">\n        <a role=\"button\" class=\"markdown_preview compose_control_button zulip-icon zulip-icon-preview\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Preview",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":115},"end":{"line":4,"column":131}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"compose_control_button_container\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Write",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":70},"end":{"line":6,"column":84}}}))
    + "\">\n        <a role=\"button\" class=\"undo_markdown_preview compose_control_button zulip-icon zulip-icon-compose-edit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Write",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":125},"end":{"line":7,"column":139}}}))
    + "\" tabindex=0 style=\"display:none;\"></a>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"file_upload_enabled"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":13,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add video call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":92},"end":{"line":14,"column":115}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-video-call video_link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add video call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":112},"end":{"line":15,"column":135}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add voice call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":92},"end":{"line":17,"column":115}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-voice-call audio_link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add voice call",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":112},"end":{"line":18,"column":135}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"divider\">|</div>\n    <div class=\"compose_control_button_container preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":92},"end":{"line":21,"column":110}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-smile-bigger emoji_map\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":113},"end":{"line":22,"column":131}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"compose_control_button_container preview_mode_disabled\" data-tooltip-template-id=\"add-global-time-tooltip\" data-tippy-maxWidth=\"none\">\n        <a role=\"button\" class=\"compose_control_button zulip-icon zulip-icon-time time_pick\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add global time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":105},"end":{"line":25,"column":129}}}))
    + "\" tabindex=0></a>\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"message_id"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":4},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "    <div class=\"compose_control_button_container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"giphy_enabled"),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":49},"end":{"line":32,"column":90}}})) != null ? stack1 : "")
    + " preview_mode_disabled\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":134},"end":{"line":32,"column":150}}}))
    + "\">\n        <a role=\"button\" class=\"compose_control_button compose_gif_icon zulip-icon zulip-icon-gif\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add GIF",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":111},"end":{"line":33,"column":127}}}))
    + "\" tabindex=0></a>\n    </div>\n    <div class=\"show_popover_buttons_2\">\n        <div class=\"divider\">|</div>\n        "
    + ((stack1 = container.invokePartial(require("./popovers/compose_control_buttons/compose_control_buttons_in_popover_2.hbs"),depth0,{"name":"popovers/compose_control_buttons/compose_control_buttons_in_popover_2","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"show_popover_buttons\">\n        <div class=\"divider\">|</div>\n        "
    + ((stack1 = container.invokePartial(require("./popovers/compose_control_buttons/compose_control_buttons_in_popover.hbs"),depth0,{"name":"popovers/compose_control_buttons/compose_control_buttons_in_popover","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"compose_control_menu_wrapper\" role=\"button\" tabindex=0>\n        <a class=\"compose_control_button zulip-icon zulip-icon-more-vertical hide compose_control_menu\" tabindex=\"-1\" data-tippy-content=\"Compose actions\"></a>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});