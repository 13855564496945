var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./language_selection_widget.hbs"),depth0,{"name":"language_selection_widget","hash":{"language_code":lookupProperty(depth0,"default_language"),"section_title":lookupProperty(lookupProperty(depth0,"settings_label"),"default_language_settings_label"),"setting_value":lookupProperty(depth0,"default_language_name"),"section_name":"default_language_name"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value='"
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":21,"column":38},"end":{"line":21,"column":48}} ), depth0))
    + "'>"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":21,"column":56},"end":{"line":21,"column":72}} ), depth0))
    + "</option>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label class=\"preferences-radio-choice-label\">\n                        <div class=\"radio-choice-controls\">\n                            <input type=\"radio\" class=\"setting_emojiset_choice\" name=\"emojiset\" value=\""
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":45,"column":105},"end":{"line":45,"column":113}} ), depth0))
    + "\"/>\n                            <span>"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":46,"column":36},"end":{"line":46,"column":45}} ), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"key"),"google-blob",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":47,"column":34},"end":{"line":47,"column":61}}}),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":28},"end":{"line":50,"column":35}}})) != null ? stack1 : "")
    + "                        </div>\n                        <span class=\"right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"key"),"text",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":53,"column":34},"end":{"line":53,"column":54}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":53,"column":28},"end":{"line":60,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </label>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <span>(<em>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"deprecated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":39},"end":{"line":48,"column":58}}}))
    + "</em>)</span>\n                            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/emoji-and-emoticons#change-your-emoji-set"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                            <div class=\"emoji_alt_code\">&nbsp;:relaxed:</div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":56,"column":85},"end":{"line":56,"column":93}} ), depth0))
    + "-64/1f642.png\" />\n                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":57,"column":85},"end":{"line":57,"column":93}} ), depth0))
    + "-64/1f44d.png\" />\n                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":58,"column":85},"end":{"line":58,"column":93}} ), depth0))
    + "-64/1f680.png\" />\n                            <img class=\"emoji\" src=\"/static/generated/emoji/images-"
    + alias3(alias2(alias1(depth0, "key", {"start":{"line":59,"column":85},"end":{"line":59,"column":93}} ), depth0))
    + "-64/1f389.png\" />\n";
},"16":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <label class=\"preferences-radio-choice-label\">\n                        <div class=\"radio-choice-controls\">\n                            <input type=\"radio\" class=\"setting_user_list_style_choice\" name=\"user_list_style\" value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":102,"column":119},"end":{"line":102,"column":128}} ), depth0))
    + "\"/>\n                            <span>"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":103,"column":36},"end":{"line":103,"column":52}} ), depth0))
    + "</span>\n                        </div>\n                        <span class=\"right preview\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),1,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":106,"column":34},"end":{"line":106,"column":50}}}),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":106,"column":28},"end":{"line":111,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),2,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":112,"column":34},"end":{"line":112,"column":50}}}),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":28},"end":{"line":120,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </label>\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                            <div class=\"user-name-and-status-emoji\">\n                                <span class=\"user-name\">"
    + container.escapeExpression(container.lambda(container.strict(depths[1], "full_name", {"start":{"line":108,"column":58},"end":{"line":108,"column":70}} ), depth0))
    + "</span>\n                                "
    + ((stack1 = container.invokePartial(require("../status_emoji.hbs"),depth0,{"name":"../status_emoji","hash":{"emoji_code":"1f3e0"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <div class=\"user-name-and-status-text\">\n                                <div class=\"user-name-and-status-emoji\">\n                                    <span class=\"user-name\">"
    + alias1(container.lambda(container.strict(depths[1], "full_name", {"start":{"line":115,"column":62},"end":{"line":115,"column":74}} ), depth0))
    + "</span>\n                                    "
    + ((stack1 = container.invokePartial(require("../status_emoji.hbs"),depth0,{"name":"../status_emoji","hash":{"emoji_code":"1f3e0"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                                </div>\n                                <span class=\"status-text\">"
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Working remotely",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":58},"end":{"line":118,"column":83}}}))
    + "</span>\n                            </div>\n";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"information-density-settings\">\n            <div class=\"title\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Information density settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":160,"column":31},"end":{"line":160,"column":67}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(lookupProperty(depth0,"information_density_settings"),"settings"),"user_display_settings"),{"name":"each","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":161,"column":12},"end":{"line":168,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = container.invokePartial(require("./settings_numeric_input.hbs"),depth0,{"name":"settings_numeric_input","hash":{"prefix":lookupProperty(depths[1],"prefix"),"render_only":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(lookupProperty(depths[1],"information_density_settings"),"render_only"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":166,"column":30},"end":{"line":166,"column":87}}}),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_label"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":165,"column":24},"end":{"line":165,"column":55}}}),"setting_value":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_object"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":164,"column":32},"end":{"line":164,"column":64}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depths[1],"prefix"),"render_only":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(lookupProperty(depths[1],"display_settings"),"render_only"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":177,"column":26},"end":{"line":177,"column":71}}}),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_label"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":176,"column":20},"end":{"line":176,"column":51}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_object"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":175,"column":25},"end":{"line":175,"column":57}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"preferences-settings-form\">\n    <div class=\"general-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":33},"end":{"line":2,"column":117}}})) != null ? stack1 : "")
    + "\">\n        <!-- this is inline block so that the alert notification can sit beside\n        it. If there's not an alert, don't make it inline-block.-->\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"General",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":32}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":7,"column":97},"end":{"line":7,"column":121}}}),"section_name":"general-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"unless","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":15,"column":19}}})) != null ? stack1 : "")
    + "\n        <div class=\"input-group\">\n            <label for=\"twenty_four_hour_time\" class=\"dropdown-title\">"
    + alias2(alias4(alias3(lookupProperty(depth0,"settings_label"), "twenty_four_hour_time", {"start":{"line":18,"column":73},"end":{"line":18,"column":109}} ), depth0))
    + "</label>\n            <select name=\"twenty_four_hour_time\" class=\"setting_twenty_four_hour_time prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":19,"column":144},"end":{"line":19,"column":150}} ), depth0))
    + "twenty_four_hour_time\" data-setting-widget-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"twenty_four_hour_time_values"),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"color_scheme\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":61},"end":{"line":26,"column":75}}}))
    + "</label>\n            <select name=\"color_scheme\" class=\"setting_color_scheme prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":27,"column":126},"end":{"line":27,"column":132}} ), depth0))
    + "color_scheme\" data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"color_scheme_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n\n    <div class=\"emoji-preferences "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":33,"column":34},"end":{"line":33,"column":118}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"subsection-header\">\n            <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":30},"end":{"line":35,"column":44}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":36,"column":107},"end":{"line":36,"column":131}}}),"section_name":"emoji-preferences-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"input-group\">\n            <label class=\"emoji-theme title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Emoji theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":45},"end":{"line":40,"column":65}}}))
    + "</label>\n            <div class=\"emojiset_choices grey-box prop-element\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":41,"column":70},"end":{"line":41,"column":76}} ), depth0))
    + "emojiset\" data-setting-widget-type=\"radio-group\" data-setting-choice-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"settings_object"),"emojiset_choices"),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":16},"end":{"line":63,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"help_link":"/help/configure-emoticon-translations","label":lookupProperty(lookupProperty(depth0,"settings_label"),"translate_emoticons"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"translate_emoticons"),"setting_name":"translate_emoticons"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"display_emoji_reaction_users"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"display_emoji_reaction_users"),"setting_name":"display_emoji_reaction_users"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"advanced-settings "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":81,"column":34},"end":{"line":81,"column":118}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"subsection-header\">\n            <h3 class=\"light\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Advanced",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":83,"column":30},"end":{"line":83,"column":47}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":84,"column":98},"end":{"line":84,"column":122}}}),"section_name":"advanced-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"input-group\">\n            <label for=\"web_mark_read_on_scroll_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Automatically mark messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":88,"column":79},"end":{"line":88,"column":123}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/marking-messages-as-read"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"web_mark_read_on_scroll_policy\" class=\"setting_web_mark_read_on_scroll_policy prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":91,"column":162},"end":{"line":91,"column":168}} ), depth0))
    + "web_mark_read_on_scroll_policy\"  data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_mark_read_on_scroll_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"input-group\">\n            <label class=\"title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"User list style",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":33},"end":{"line":97,"column":57}}}))
    + "</label>\n            <div class=\"user_list_style_values grey-box prop-element\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":98,"column":76},"end":{"line":98,"column":82}} ), depth0))
    + "user_list_style\" data-setting-widget-type=\"radio-group\" data-setting-choice-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"user_list_style_values"),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":16},"end":{"line":123,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"input-group thinner setting-next-is-related\">\n            <label for=\"web_home_view\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Home view",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":128,"column":62},"end":{"line":128,"column":80}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/configure-home-view"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"web_home_view\" class=\"setting_web_home_view prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":131,"column":128},"end":{"line":131,"column":134}} ), depth0))
    + "web_home_view\" data-setting-widget-type=\"string\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_home_view_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depth0,"prefix"),"label":lookupProperty(lookupProperty(depth0,"settings_label"),"web_escape_navigates_to_home_view"),"is_checked":lookupProperty(lookupProperty(depth0,"settings_object"),"web_escape_navigates_to_home_view"),"setting_name":"web_escape_navigates_to_home_view"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"input-group\">\n            <label for=\"demote_inactive_streams\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Demote inactive streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":143,"column":72},"end":{"line":143,"column":104}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/manage-inactive-streams"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"demote_inactive_streams\" class=\"setting_demote_inactive_streams prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":146,"column":148},"end":{"line":146,"column":154}} ), depth0))
    + "demote_inactive_streams\"  data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"demote_inactive_streams_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"input-group\">\n            <label for=\"web_stream_unreads_count_display_policy\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show unread counts for",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":152,"column":88},"end":{"line":152,"column":119}}}))
    + "</label>\n            <select name=\"web_stream_unreads_count_display_policy\" class=\"setting_web_stream_unreads_count_display_policy prop-element bootstrap-focus-style settings_select\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":153,"column":180},"end":{"line":153,"column":186}} ), depth0))
    + "web_stream_unreads_count_display_policy\"  data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"web_stream_unreads_count_display_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(lookupProperty(depth0,"information_density_settings"),"render_group"),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":8},"end":{"line":170,"column":15}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(lookupProperty(depth0,"display_settings"),"settings"),"user_display_settings"),{"name":"each","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":172,"column":8},"end":{"line":179,"column":17}}})) != null ? stack1 : "")
    + "\n    </div>\n</form>\n";
},"usePartial":true,"useData":true,"useDepths":true});