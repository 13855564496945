var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "id=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "id", {"start":{"line":3,"column":29},"end":{"line":3,"column":31}} ), depth0))
    + "\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.invokePartial(require("./help_link_widget.hbs"),depth0,{"name":"help_link_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "data-simplebar-auto-hide=\"false\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button class=\"modal__btn dialog_exit_button\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":74},"end":{"line":19,"column":107}}}))
    + "\" data-micromodal-close>"
    + ((stack1 = container.lambda(container.strict(depth0, "html_exit_button", {"start":{"line":19,"column":135},"end":{"line":19,"column":151}} ), depth0)) != null ? stack1 : "")
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Close this dialog window",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":108},"end":{"line":22,"column":141}}}))
    + "\" data-micromodal-close";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"micromodal\" id=\"dialog_widget_modal\" aria-hidden=\"true\">\n    <div class=\"modal__overlay\" tabindex=\"-1\">\n        <div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"id"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":13},"end":{"line":3,"column":42}}})) != null ? stack1 : "")
    + "class=\"modal__container\" role=\"dialog\" aria-modal=\"true\" aria-labelledby=\"dialog_title\">\n            <header class=\"modal__header\">\n                <h1 class=\"modal__title dialog_heading\">\n                    "
    + ((stack1 = alias3(alias2(depth0, "heading_text", {"start":{"line":6,"column":24},"end":{"line":6,"column":36}} ), depth0)) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"link"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":9,"column":27}}})) != null ? stack1 : "")
    + "                </h1>\n                <button class=\"modal__close\" aria-label=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Close modal",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":57},"end":{"line":11,"column":77}}}))
    + "\" data-micromodal-close></button>\n            </header>\n            <main class=\"modal__content\" data-simplebar "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"always_visible_scrollbar"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":56},"end":{"line":13,"column":127}}})) != null ? stack1 : "")
    + ">\n                <div class=\"alert\" id=\"dialog_error\"></div>\n                "
    + ((stack1 = alias3(alias2(depth0, "html_body", {"start":{"line":15,"column":20},"end":{"line":15,"column":29}} ), depth0)) != null ? stack1 : "")
    + "\n            </main>\n            <footer class=\"modal__footer\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"single_footer_button"),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":27}}})) != null ? stack1 : "")
    + "                <div class=\"dialog_submit_button_container\">\n                    <button class=\"modal__btn dialog_submit_button\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"single_footer_button"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":67},"end":{"line":22,"column":171}}})) != null ? stack1 : "")
    + ">\n                        <span>"
    + ((stack1 = alias3(alias2(depth0, "html_submit_button", {"start":{"line":23,"column":34},"end":{"line":23,"column":52}} ), depth0)) != null ? stack1 : "")
    + "</span>\n                        <div class=\"modal__spinner\"></div>\n                    </button>\n                </div>\n            </footer>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});