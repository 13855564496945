var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a tabindex=\"0\" id=\"mark_all_messages_as_read\">\n            <i class=\"fa fa-book\" aria-hidden=\"true\"></i>\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Mark all messages as read",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":46}}}))
    + "\n        </a>\n    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <a tabindex=\"0\" class=\"set-home-view\" data-view-code=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"view_code") || container.strict(depth0, "view_code", {"start":{"line":13,"column":64},"end":{"line":13,"column":73}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"view_code","hash":{},"data":data,"loc":{"start":{"line":13,"column":62},"end":{"line":13,"column":75}}}) : helper)))
    + "\">\n            <i class=\"fa fa-home\" aria-hidden=\"true\"></i>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "        </a>\n    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "                Make <b>inbox</b> my home view\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_home_view"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_home_view"),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});