var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"lightbox_overlay\" class=\"overlay new-style\" data-overlay=\"lightbox\" data-noclose=\"false\">\n    <div class=\"media-info-wrapper\">\n        <div class=\"media-description\">\n            <div class=\"title\"></div>\n            <div class=\"user\"></div>\n        </div>\n        <div class=\"media-actions\">\n            <a class=\"button small lightbox-zoom-reset disabled\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reset zoom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":8,"column":84}}}))
    + "</a>\n            <a class=\"button small open\" rel=\"noopener noreferrer\" target=\"_blank\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Open",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":83},"end":{"line":9,"column":96}}}))
    + "</a>\n            <a class=\"button small download\" download>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Download",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":54},"end":{"line":10,"column":71}}}))
    + "</a>\n        </div>\n        <div class=\"exit\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":38},"end":{"line":12,"column":52}}}))
    + "\"><span aria-hidden=\"true\">x</span></div>\n    </div>\n\n    <div class=\"image-preview no-select\">\n        <div class=\"zoom-element no-select\"></div>\n    </div>\n    <div class=\"video-player\"></div>\n    <div class=\"player-container\"></div>\n    <div class=\"center\">\n        <div class=\"arrow no-select\" data-direction=\"prev\">&lt;</div>\n        <div class=\"image-list\"></div>\n        <div class=\"arrow no-select\" data-direction=\"next\">&gt;</div>\n    </div>\n</div>\n";
},"useData":true});