var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"hotspot_"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":1,"column":19},"end":{"line":1,"column":23}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":17},"end":{"line":1,"column":25}}}) : helper)))
    + "_overlay\" class=\"hotspot overlay\" data-overlay=\"hotspot_"
    + alias4((((helper = lookupProperty(helpers,"name") || alias1(depth0, "name", {"start":{"line":1,"column":83},"end":{"line":1,"column":87}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":1,"column":81},"end":{"line":1,"column":89}}}) : helper)))
    + "_overlay\">\n    <div class=\"hotspot-popover\">\n        <div class=\"hotspot-popover-top\">\n            <h1 class=\"hotspot-title\">"
    + alias4((((helper = lookupProperty(helpers,"title") || alias1(depth0, "title", {"start":{"line":4,"column":40},"end":{"line":4,"column":45}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":4,"column":38},"end":{"line":4,"column":47}}}) : helper)))
    + "</h1>\n        </div>\n        <div class=\"hotspot-popover-content\">\n            <p class=\"hotspot-description\">"
    + alias4((((helper = lookupProperty(helpers,"description") || alias1(depth0, "description", {"start":{"line":7,"column":45},"end":{"line":7,"column":56}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":7,"column":43},"end":{"line":7,"column":58}}}) : helper)))
    + "</p>\n        </div>\n        <div class=\"hotspot-popover-bottom\">\n            <img class=\"hotspot-img\" src=\""
    + alias4((((helper = lookupProperty(helpers,"img") || alias1(depth0, "img", {"start":{"line":10,"column":44},"end":{"line":10,"column":47}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"img","hash":{},"data":data,"loc":{"start":{"line":10,"column":42},"end":{"line":10,"column":49}}}) : helper)))
    + "\" />\n            <button class=\"hotspot-confirm\">"
    + alias4(lookupProperty(helpers,"t").call(alias2,"Got it!",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":44},"end":{"line":11,"column":60}}}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});