var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"recent_view_filter_buttons\" class=\"btn-group\" role=\"group\">\n    <div id=\"recent_filters_group\">\n        "
    + ((stack1 = container.invokePartial(require("./recent_view_filters.hbs"),depth0,{"name":"recent_view_filters","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"search_group\" role=\"group\">\n        <input type=\"text\" id=\"recent_view_search\" class=\"filter_text_input\" value=\""
    + alias1(container.lambda(container.strict(depth0, "search_val", {"start":{"line":6,"column":87},"end":{"line":6,"column":97}} ), depth0))
    + "\" autocomplete=\"off\" placeholder=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Filter topics (t)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":134},"end":{"line":6,"column":160}}}))
    + "\" />\n        <button type=\"button\" class=\"btn clear_search_button\" id=\"recent_view_search_clear\">\n            <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n        </button>\n    </div>\n</div>\n<div class=\"table_fix_head\" data-simplebar>\n    <div class=\"recent-view-container\">\n        <table class=\"table table-responsive\">\n            <tbody data-empty=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"No conversations match your filters.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":31},"end":{"line":15,"column":76}}}))
    + "\" class=\"required-text\"></tbody>\n            <thead>\n                <tr>\n                    <th data-sort=\"stream_sort\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":48},"end":{"line":18,"column":63}}}))
    + "</th>\n                    <th data-sort=\"topic_sort\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":47},"end":{"line":19,"column":61}}}))
    + "</th>\n                    <th data-sort=\"unread_sort\" data-tippy-content=\""
    + alias1(lookupProperty(helpers,"t").call(alias2,"Sort by unread message count",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":68},"end":{"line":20,"column":105}}}))
    + "\" class=\"unread_sort tippy-zulip-delayed-tooltip hidden-for-spectators\">\n                        <i class=\"zulip-icon zulip-icon-unread\"></i>\n                    </th>\n                    <th class='participants_header'>"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Participants",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":52},"end":{"line":23,"column":73}}}))
    + "</th>\n                    <th data-sort=\"numeric\" data-sort-prop=\"last_msg_id\" class=\"last_msg_time_header active descend\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":117},"end":{"line":24,"column":130}}}))
    + "</th>\n                </tr>\n            </thead>\n        </table>\n        <div class=\"recent-view-load-more-container main-view-banner info notvisible\">\n            <div class=\"last-fetched-message banner_content\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"This view is still loading messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":61},"end":{"line":30,"column":105}}}))
    + "</div>\n            <button class=\"fetch-messages-button main-view-banner-action-button right_edge notvisible\">\n                <div class=\"loading-indicator\"></div>\n                <span class=\"button-label\">"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Load more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":43},"end":{"line":33,"column":60}}}))
    + "</span>\n            </button>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});