var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"plain_text",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":3,"column":10},"end":{"line":3,"column":37}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":39,"column":13}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(data,"last"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":40,"column":11},"end":{"line":40,"column":22}}}),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":4},"end":{"line":40,"column":35}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "content", {"start":{"line":4,"column":11},"end":{"line":4,"column":23}} ), depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"stream_topic",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":43}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":39,"column":4}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "stream "
    + alias3(alias2(alias1(depth0, "stream", {"start":{"line":7,"column":17},"end":{"line":7,"column":28}} ), depth0))
    + " > "
    + alias3(alias2(alias1(depth0, "topic", {"start":{"line":7,"column":35},"end":{"line":7,"column":45}} ), depth0));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"invalid_has",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":9,"column":14},"end":{"line":9,"column":42}}}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":39,"column":4}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "invalid "
    + container.escapeExpression(container.lambda(container.strict(depth0, "operand", {"start":{"line":11,"column":18},"end":{"line":11,"column":30}} ), depth0))
    + " operand for has operator";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"prefix_for_operator",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":13,"column":14},"end":{"line":13,"column":50}}}),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":39,"column":4}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return alias3(alias2(alias1(depth0, "prefix_for_operator", {"start":{"line":15,"column":10},"end":{"line":15,"column":34}} ), depth0))
    + " "
    + alias3(alias2(alias1(depth0, "operand", {"start":{"line":15,"column":39},"end":{"line":15,"column":51}} ), depth0));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"type"),"is_operator",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":42}}}),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":39,"column":4}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"mentioned",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":18,"column":14},"end":{"line":18,"column":43}}}),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":38,"column":17}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":20,"column":14},"end":{"line":20,"column":23}} ), depth0))
    + "@-mentions";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"starred",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":22,"column":22},"end":{"line":22,"column":49}}}),lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"alerted",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":22,"column":50},"end":{"line":22,"column":77}}}),lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"unread",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":22,"column":78},"end":{"line":22,"column":104}}}),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":22,"column":18},"end":{"line":22,"column":105}}}),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":38,"column":8}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return alias3(alias2(alias1(depth0, "verb", {"start":{"line":24,"column":14},"end":{"line":24,"column":23}} ), depth0))
    + alias3(alias2(alias1(depth0, "operand", {"start":{"line":24,"column":27},"end":{"line":24,"column":39}} ), depth0))
    + " messages";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"dm",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":26,"column":22},"end":{"line":26,"column":44}}}),lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"private",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":26,"column":45},"end":{"line":26,"column":72}}}),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":26,"column":18},"end":{"line":26,"column":73}}}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":38,"column":8}}})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":28,"column":14},"end":{"line":28,"column":23}} ), depth0))
    + "direct messages";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"operand"),"resolved",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":30,"column":18},"end":{"line":30,"column":46}}}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":30,"column":8},"end":{"line":38,"column":8}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "verb", {"start":{"line":32,"column":14},"end":{"line":32,"column":23}} ), depth0))
    + "topics marked as resolved";
},"26":function(container,depth0,helpers,partials,data) {
    return "invalid "
    + container.escapeExpression(container.lambda(container.strict(depth0, "operand", {"start":{"line":36,"column":22},"end":{"line":36,"column":34}} ), depth0))
    + " operand for is operator";
},"28":function(container,depth0,helpers,partials,data) {
    return ", ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"parts"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":42,"column":11}}})) != null ? stack1 : "");
},"useData":true});