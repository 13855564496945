var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_disabled"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":72}}})) != null ? stack1 : "")
    + "\">\n    <label class=\"checkbox\">\n        <input type=\"checkbox\" class=\""
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":4,"column":40},"end":{"line":4,"column":52}} ), depth0))
    + " inline-block setting-widget prop-element\" name=\""
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":4,"column":105},"end":{"line":4,"column":117}} ), depth0))
    + "\" data-setting-widget-type=\"boolean\"\n          id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":14},"end":{"line":5,"column":45}}})) != null ? stack1 : "")
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":5,"column":47},"end":{"line":5,"column":59}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_checked"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":63},"end":{"line":5,"column":105}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_disabled"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":106},"end":{"line":5,"column":140}}})) != null ? stack1 : "")
    + " />\n        <span></span>\n    </label>\n    <label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":47}}})) != null ? stack1 : "")
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":8,"column":49},"end":{"line":8,"column":61}} ), depth0))
    + "\" class=\"inline "
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":8,"column":81},"end":{"line":8,"column":93}} ), depth0))
    + "_label\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"prefix"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":107},"end":{"line":8,"column":138}}})) != null ? stack1 : "")
    + alias4(alias3(alias2(depth0, "setting_name", {"start":{"line":8,"column":140},"end":{"line":8,"column":152}} ), depth0))
    + "_label\">\n        "
    + alias4(alias3(alias2(depth0, "label", {"start":{"line":9,"column":10},"end":{"line":9,"column":15}} ), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"label_parens_text"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"help_link"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"tooltip_text"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":18,"column":15}}})) != null ? stack1 : "")
    + "    </label>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled";
},"4":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "prefix", {"start":{"line":5,"column":30},"end":{"line":5,"column":36}} ), depth0));
},"6":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"8":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"10":function(container,depth0,helpers,partials,data) {
    return "        (<i>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "label_parens_text", {"start":{"line":11,"column":14},"end":{"line":11,"column":31}} ), depth0))
    + "</i>)\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":lookupProperty(depth0,"help_link")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"tippy-zulip-tooltip fa fa-info-circle settings-info-icon\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"hide_tooltip"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":76},"end":{"line":17,"column":125}}})) != null ? stack1 : "")
    + " data-tippy-content=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "tooltip_text", {"start":{"line":17,"column":148},"end":{"line":17,"column":160}} ), depth0))
    + "\"></i>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"render_only"),false,{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":32}}}),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":21,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});