var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"add_subscribers_container\">\n    <div class=\"pill-container person_picker\">\n        <div class=\"input\" contenteditable=\"true\"\n          data-placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add subscribers. Use usergroup or #streamname to bulk add subscribers.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":28},"end":{"line":4,"column":107}}}))
    + "\"></div>\n    </div>\n    <div class=\"add_subscriber_btn_wrapper inline-block\">\n        <button type=\"submit\" name=\"add_subscriber\" class=\"button add-subscriber-button add-users-button small rounded sea-green\" tabindex=\"0\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":24}}}))
    + "\n        </button>\n    </div>\n</div>\n";
},"useData":true});