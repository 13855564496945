var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group\">\n            <label for=\"embedded_bot_"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(data, "key", {"start":{"line":4,"column":39},"end":{"line":4,"column":43}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":45}}}) : helper)))
    + "_edit\">"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(data, "key", {"start":{"line":4,"column":54},"end":{"line":4,"column":58}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":4,"column":52},"end":{"line":4,"column":60}}}) : helper)))
    + "</label>\n            <input type=\"text\" name=\""
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(data, "key", {"start":{"line":5,"column":39},"end":{"line":5,"column":43}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":5,"column":37},"end":{"line":5,"column":45}}}) : helper)))
    + "\" id=\"embedded_bot_"
    + alias4((((helper = lookupProperty(helpers,"key") || alias1(data, "key", {"start":{"line":5,"column":66},"end":{"line":5,"column":70}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":72}}}) : helper)))
    + "_edit\" class=\"modal_text_input\"\n              maxlength=1000 value=\""
    + alias4(container.lambda(depth0, depth0))
    + "\" />\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"config_edit_inputbox\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"service"),"config_data"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":8,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});