var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " deactivated_user";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"my_user_status\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(you)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":78}}}))
    + "</span>";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"fa fa-ban deactivated-user-icon tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Bot is deactivated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":99},"end":{"line":7,"column":126}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":128},"end":{"line":7,"column":174}}})) != null ? stack1 : "")
    + "></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "style=\"display: none;\"";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <i class=\"fa fa-ban deactivated-user-icon tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"User is deactivated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":99},"end":{"line":9,"column":127}}}))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":129},"end":{"line":9,"column":175}}})) != null ? stack1 : "")
    + "></i>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"email\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"display_email") || container.strict(depth0, "display_email", {"start":{"line":14,"column":30},"end":{"line":14,"column":43}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"display_email","hash":{},"data":data,"loc":{"start":{"line":14,"column":28},"end":{"line":14,"column":45}}}) : helper)))
    + "</span>\n    </td>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td>\n        <span class=\"hidden-email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":35},"end":{"line":18,"column":51}}}))
    + "</span>\n    </td>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"user_role\">\n        <span class=\"owner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"no_owner"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":27,"column":12},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n    <td class=\"bot_type\">\n        <span class=\"bot type\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"bot_type") || container.strict(depth0, "bot_type", {"start":{"line":35,"column":33},"end":{"line":35,"column":41}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"bot_type","hash":{},"data":data,"loc":{"start":{"line":35,"column":31},"end":{"line":35,"column":43}}}) : helper)))
    + "</span>\n    </td>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression((((helper = lookupProperty(helpers,"bot_owner_full_name") || container.strict(depth0, "bot_owner_full_name", {"start":{"line":28,"column":14},"end":{"line":28,"column":33}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bot_owner_full_name","hash":{},"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":28,"column":35}}}) : helper)))
    + "\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <a data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"bot_owner_id") || alias1(depth0, "bot_owner_id", {"start":{"line":30,"column":31},"end":{"line":30,"column":43}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"bot_owner_id","hash":{},"data":data,"loc":{"start":{"line":30,"column":29},"end":{"line":30,"column":45}}}) : helper)))
    + "\" class=\"view_user_profile\" tabindex=\"0\">"
    + alias4((((helper = lookupProperty(helpers,"bot_owner_full_name") || alias1(depth0, "bot_owner_full_name", {"start":{"line":30,"column":88},"end":{"line":30,"column":107}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"bot_owner_full_name","hash":{},"data":data,"loc":{"start":{"line":30,"column":86},"end":{"line":30,"column":109}}}) : helper)))
    + "</a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":41,"column":4}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"last_active\">\n        "
    + container.escapeExpression((((helper = lookupProperty(helpers,"last_active_date") || container.strict(depth0, "last_active_date", {"start":{"line":39,"column":11},"end":{"line":39,"column":27}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"last_active_date","hash":{},"data":data,"loc":{"start":{"line":39,"column":8},"end":{"line":39,"column":30}}}) : helper)))
    + "\n    </td>\n    ";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"actions\">\n        <span class=\"user-status-settings\">\n            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_edit"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":48}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":18},"end":{"line":45,"column":84}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_edit"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":45,"column":91},"end":{"line":45,"column":115}}}),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":85},"end":{"line":45,"column":179}}})) != null ? stack1 : "")
    + ">\n                <button class=\"button rounded small btn-warning open-user-form tippy-zulip-delayed-tooltip\" data-tippy-content=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":46,"column":128},"end":{"line":46,"column":226}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"user_id") || container.strict(depth0, "user_id", {"start":{"line":46,"column":244},"end":{"line":46,"column":251}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":46,"column":242},"end":{"line":46,"column":253}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_edit"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":255},"end":{"line":46,"column":300}}})) != null ? stack1 : "")
    + ">\n                    <i class=\"fa fa-pencil\" aria-hidden=\"true\"></i>\n                </button>\n            </span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(39, data, 0),"data":data,"loc":{"start":{"line":50,"column":12},"end":{"line":60,"column":19}}})) != null ? stack1 : "")
    + "        </span>\n    </td>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return "class=\"tippy-zulip-tooltip\"";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot cannot be edited.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":137},"end":{"line":45,"column":171}}}))
    + "\"";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"cannot_edit"),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":142},"end":{"line":46,"column":193}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit bot",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":165},"end":{"line":46,"column":182}}}));
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":201},"end":{"line":46,"column":219}}}));
},"32":function(container,depth0,helpers,partials,data) {
    return "disabled=\"disabled\"";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":51,"column":24},"end":{"line":51,"column":54}}}),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":18},"end":{"line":51,"column":90}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":51,"column":97},"end":{"line":51,"column":127}}}),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":91},"end":{"line":51,"column":196}}})) != null ? stack1 : "")
    + ">\n                <button class=\"button rounded small btn-danger "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depth0,"is_bot"),lookupProperty(depth0,"cannot_deactivate"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":52,"column":73},"end":{"line":52,"column":103}}}),{"name":"unless","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":63},"end":{"line":52,"column":127}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"cannot_deactivate"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":129},"end":{"line":52,"column":180}}})) != null ? stack1 : "")
    + ">\n                    <i class=\"fa fa-user-times\" aria-hidden=\"true\"></i>\n                </button>\n            </span>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-tippy-content=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This bot cannot be deactivated.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":149},"end":{"line":51,"column":188}}}))
    + "\"";
},"37":function(container,depth0,helpers,partials,data) {
    return "deactivate";
},"39":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"button rounded small reactivate btn-warning\">\n                <i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"user_row"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_active"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":68}}})) != null ? stack1 : "")
    + "\" data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias2(depth0, "user_id", {"start":{"line":1,"column":86},"end":{"line":1,"column":93}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":1,"column":84},"end":{"line":1,"column":95}}}) : helper)))
    + "\">\n    <td>\n        <span class=\"user_name\" >\n            <a data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias2(depth0, "user_id", {"start":{"line":4,"column":31},"end":{"line":4,"column":38}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":4,"column":29},"end":{"line":4,"column":40}}}) : helper)))
    + "\" class=\"view_user_profile\" tabindex=\"0\">"
    + alias4((((helper = lookupProperty(helpers,"full_name") || alias2(depth0, "full_name", {"start":{"line":4,"column":83},"end":{"line":4,"column":92}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":81},"end":{"line":4,"column":94}}}) : helper)))
    + "</a>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_current_user"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":92}}})) != null ? stack1 : "")
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"display_email"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <span class=\"user_role\">"
    + alias4((((helper = lookupProperty(helpers,"user_role_text") || alias2(depth0, "user_role_text", {"start":{"line":22,"column":34},"end":{"line":22,"column":48}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_role_text","hash":{},"data":data,"loc":{"start":{"line":22,"column":32},"end":{"line":22,"column":50}}}) : helper)))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":41,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"can_modify"),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":63,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"useData":true});