var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"status-circle "
    + alias4((((helper = lookupProperty(helpers,"user_circle_class") || alias1(depth0, "user_circle_class", {"start":{"line":8,"column":45},"end":{"line":8,"column":62}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":8,"column":43},"end":{"line":8,"column":64}}}) : helper)))
    + " user_circle hidden-for-spectators\" data-tippy-placement=\"bottom\" data-tippy-content=\""
    + alias4((((helper = lookupProperty(helpers,"user_last_seen_time_status") || alias1(depth0, "user_last_seen_time_status", {"start":{"line":8,"column":152},"end":{"line":8,"column":178}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_last_seen_time_status","hash":{},"data":data,"loc":{"start":{"line":8,"column":150},"end":{"line":8,"column":180}}}) : helper)))
    + "\"></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"popover-menu-outer-list-item\">\n                    <ul class=\"popover-menu-inner-list\">\n                        <li class=\"text-item hidden-for-spectators popover-menu-inner-list-item\">\n                            <i class=\"popover-menu-icon zulip-icon zulip-icon-clock\"></i>\n                            "
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":24,"column":64}}})) != null ? stack1 : "")
    + "\n                        </li>\n                    </ul>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "{user_time} local time";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"text-item popover-menu-inner-list-item\">\n                            <span class=\"personal-menu-status-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_emoji_info"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":32},"end":{"line":42,"column":39}}})) != null ? stack1 : "")
    + "                                <span class=\"status_text personal-menu-status-text\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_placeholder_for_status_text"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":44,"column":36},"end":{"line":48,"column":43}}})) != null ? stack1 : "")
    + "                                </span>\n                            </span>\n                            <a tabindex=\"0\" class=\"personal-menu-clear-status popover-menu-link\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":109},"end":{"line":51,"column":129}}}))
    + "\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Clear your status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":151},"end":{"line":51,"column":177}}}))
    + "\">\n                                <i class=\"personal-menu-clear-status-icon popover-menu-icon zulip-icon zulip-icon-x-circle\"></i>\n                            </a>\n                        </li>\n                        <li class=\"link-item popover-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\"></i>\n                                <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":65},"end":{"line":58,"column":85}}}))
    + "</span>\n                            </a>\n                        </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"emoji_alt_code"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":35,"column":36},"end":{"line":41,"column":43}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"emoji_alt_code\">&nbsp;:"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_name", {"start":{"line":36,"column":74},"end":{"line":36,"column":102}} ), depth0))
    + ":</span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(lookupProperty(depth0,"status_emoji_info"),"url"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":37,"column":36},"end":{"line":41,"column":36}}})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <img src=\""
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "url", {"start":{"line":38,"column":48},"end":{"line":38,"column":69}} ), depth0))
    + "\" class=\"emoji status_emoji\" />\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                    <span class=\"emoji status_emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(lookupProperty(depth0,"status_emoji_info"), "emoji_code", {"start":{"line":40,"column":76},"end":{"line":40,"column":104}} ), depth0))
    + "\"></span>\n                                    ";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        <i class=\"personal-menu-no-status-text\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"No status text",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":80},"end":{"line":45,"column":102}}}))
    + "</i>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                        "
    + container.escapeExpression((((helper = lookupProperty(helpers,"status_text") || container.strict(depth0, "status_text", {"start":{"line":47,"column":42},"end":{"line":47,"column":53}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"status_text","hash":{},"data":data,"loc":{"start":{"line":47,"column":40},"end":{"line":47,"column":55}}}) : helper)))
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"link-item hidden-for-spectators popover-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"update_status_text popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-smile-smaller\"></i>\n                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Set status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":65},"end":{"line":65,"column":84}}}))
    + "</span>\n                            </a>\n                        </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"link-item hidden-for-spectators popover-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"invisible_mode_turn_off popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-play-circle\"></i>\n                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Turn off invisible mode",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":65},"end":{"line":74,"column":97}}}))
    + "</span>\n                            </a>\n                        </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"link-item hidden-for-spectators popover-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"invisible_mode_turn_on popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-stop-circle\"></i>\n                                <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Go invisible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":65},"end":{"line":81,"column":86}}}))
    + "</span>\n                            </a>\n                        </li>\n";
},"27":function(container,depth0,helpers,partials,data) {
    return "checked";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, alias5=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"personal-menu-dropdown\" data-simplebar>\n    <nav class=\"personal-menu-nav\">\n        <header class=\"personal-menu-header\">\n            <div class=\"avatar\">\n                <img class=\"avatar-image"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":81}}})) != null ? stack1 : "")
    + "\" src=\""
    + alias4((((helper = lookupProperty(helpers,"user_avatar") || alias2(depth0, "user_avatar", {"start":{"line":5,"column":90},"end":{"line":5,"column":101}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_avatar","hash":{},"data":data,"loc":{"start":{"line":5,"column":88},"end":{"line":5,"column":103}}}) : helper)))
    + "\"/>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":9,"column":23}}})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"text-area\">\n                <p class=\"full-name\">"
    + alias4((((helper = lookupProperty(helpers,"user_full_name") || alias2(depth0, "user_full_name", {"start":{"line":13,"column":39},"end":{"line":13,"column":53}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_full_name","hash":{},"data":data,"loc":{"start":{"line":13,"column":37},"end":{"line":13,"column":55}}}) : helper)))
    + "</p>\n                <p class=\"user-type\">"
    + alias4((((helper = lookupProperty(helpers,"user_type") || alias2(depth0, "user_type", {"start":{"line":14,"column":39},"end":{"line":14,"column":48}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_type","hash":{},"data":data,"loc":{"start":{"line":14,"column":37},"end":{"line":14,"column":50}}}) : helper)))
    + "</p>\n            </div>\n        </header>\n        <section class=\"dropdown-menu-list-section personal-menu-actions\" data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias2(depth0, "user_id", {"start":{"line":17,"column":90},"end":{"line":17,"column":97}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":17,"column":88},"end":{"line":17,"column":99}}}) : helper)))
    + "\">\n            <ul class=\"popover-menu-outer-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_time"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "                <li class=\"popover-menu-outer-list-item\">\n                    <ul class=\"popover-menu-inner-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"status_content_available"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":68,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"invisible_mode"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":84,"column":31}}})) != null ? stack1 : "")
    + "                    </ul>\n                </li>\n                <li class=\"popover-menu-outer-list-item\">\n                    <ul class=\"popover-menu-inner-list\">\n                        <li class=\"popover-menu-inner-list-item\">\n                            <div id=\"theme-switcher\" class=\"tab-picker\">\n                                <input type=\"radio\" id=\"select-automatic-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias4(alias5(alias2(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"), "code", {"start":{"line":91,"column":138},"end":{"line":91,"column":172}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"automatic"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":91,"column":182},"end":{"line":91,"column":239}}}),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":176},"end":{"line":91,"column":255}}})) != null ? stack1 : "")
    + " />\n                                <label class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-automatic-theme\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Select automatic theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":135},"end":{"line":92,"column":166}}}))
    + "\" data-tooltip-template-id=\"automatic-theme-template\" tabindex=\"0\">\n                                    <i class=\"zulip-icon zulip-icon-monitor\" aria-hidden=\"true\"></i>\n                                </label>\n                                <input type=\"radio\" id=\"select-light-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias4(alias5(alias2(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"day"), "code", {"start":{"line":95,"column":134},"end":{"line":95,"column":162}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"day"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":95,"column":172},"end":{"line":95,"column":223}}}),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":166},"end":{"line":95,"column":239}}})) != null ? stack1 : "")
    + " />\n                                <label class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-light-theme\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Select light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":131},"end":{"line":96,"column":158}}}))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":180},"end":{"line":96,"column":200}}}))
    + "\" tabindex=\"0\">\n                                    <i class=\"zulip-icon zulip-icon-sun\" aria-hidden=\"true\"></i>\n                                </label>\n                                <input type=\"radio\" id=\"select-dark-theme\" class=\"tab-option\" name=\"theme-select\" data-theme-code=\""
    + alias4(alias5(alias2(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"night"), "code", {"start":{"line":99,"column":133},"end":{"line":99,"column":163}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"user_color_scheme"),lookupProperty(lookupProperty(lookupProperty(depth0,"color_scheme_values"),"night"),"code"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":99,"column":173},"end":{"line":99,"column":226}}}),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":167},"end":{"line":99,"column":242}}})) != null ? stack1 : "")
    + " />\n                                <label class=\"tab-option-content tippy-zulip-delayed-tooltip\" for=\"select-dark-theme\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Select dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":130},"end":{"line":100,"column":156}}}))
    + "\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias1,"Dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":178},"end":{"line":100,"column":197}}}))
    + "\" tabindex=\"0\">\n                                    <i class=\"zulip-icon zulip-icon-moon\" aria-hidden=\"true\"></i>\n                                </label>\n                                <span class=\"slider\"></span>\n                            </div>\n                        </li>\n                    </ul>\n                </li>\n                <li class=\"popover-menu-outer-list-item\">\n                    <ul class=\"popover-menu-inner-list\">\n                        <li class=\"link-item popover-menu-inner-list-item\">\n                            <a href=\"#user/"
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias2(depth0, "user_id", {"start":{"line":111,"column":45},"end":{"line":111,"column":52}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":111,"column":43},"end":{"line":111,"column":54}}}) : helper)))
    + "\" tabindex=\"0\" class=\"view_full_user_profile popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-account\"></i>\n                                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View your profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":113,"column":65},"end":{"line":113,"column":91}}}))
    + "</span>\n                            </a>\n                        </li>\n                        <li class=\"link-item popover-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"narrow-self-direct-message popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-users\"></i>\n                                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages with yourself",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":65},"end":{"line":119,"column":101}}}))
    + "</span>\n                            </a>\n                        </li>\n                        <li class=\"link-item popover-menu-inner-list-item\">\n                            <a tabindex=\"0\" class=\"narrow-messages-sent popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-message-square\"></i>\n                                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":65},"end":{"line":125,"column":92}}}))
    + "</span>\n                            </a>\n                        </li>\n                    </ul>\n                </li>\n                <li class=\"popover-menu-outer-list-item\">\n                    <ul class=\"popover-menu-inner-list\">\n                        <li class=\"link-item popover-menu-inner-list-item\">\n                            <a href=\"#settings/profile\" class=\"open-profile-settings popover-menu-link\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-tool\"></i>\n                                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":135,"column":65},"end":{"line":135,"column":82}}}))
    + "</span>\n                            </a>\n                        </li>\n                    </ul>\n                </li>\n                <li class=\"popover-menu-outer-list-item\">\n                    <ul class=\"popover-menu-inner-list\">\n                        <li class=\"link-item popover-menu-inner-list-item\">\n                            <a class=\"logout_button hidden-for-spectators popover-menu-link\" tabindex=\"0\">\n                                <i class=\"popover-menu-icon zulip-icon zulip-icon-log-out\" aria-hidden=\"true\"></i>\n                                <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Log out",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":145,"column":65},"end":{"line":145,"column":81}}}))
    + "</span>\n                            </a>\n                        </li>\n                    </ul>\n                </li>\n            </ul>\n        </section>\n    </nav>\n</div>\n";
},"useData":true});