var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":7,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                You must <z-link>configure your email</z-link> to access this feature.\n                \n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/demo-organizations#configure-email-for-demo-organization-owner\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"All users will need to log in again at your new organization URL.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":86}}}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":21,"column":37},"end":{"line":21,"column":46}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":21,"column":52},"end":{"line":21,"column":68}} ), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"convert-demo-organization-form\">\n    <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"user_has_email_set"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":10,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n\n    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You can convert this demo organization to a permanent Zulip organization. All users and message history will be preserved.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":7},"end":{"line":13,"column":138}}}))
    + "</p>\n    <form class=\"subdomain-setting\">\n        <div class=\"input-group\">\n            <label for=\"organization_type\" class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":66},"end":{"line":16,"column":92}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/organization-type"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </label>\n            <select name=\"organization_type\" id=\"add_organization_type\" class=\"modal_select bootstrap-focus-style\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"realm_org_type_values"),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":22,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n        </div>\n        <div class=\"input-group\">\n            <label for=\"string_id\" class=\"title inline-block\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Organization URL",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":62},"end":{"line":26,"column":87}}}))
    + "</label>\n            <div id=\"subdomain_input_container\">\n                <input id=\"new_subdomain\" type=\"text\" class=\"modal_text_input\" autocomplete=\"off\" name=\"string_id\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"acme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":128},"end":{"line":28,"column":141}}}))
    + "\"/>\n                <label for=\"string_id\" class=\"domain_label\">."
    + alias2(container.lambda(container.strict(depth0, "realm_domain", {"start":{"line":29,"column":64},"end":{"line":29,"column":76}} ), depth0))
    + "</label>\n            </div>\n        </div>\n    </form>\n</div>\n";
},"2_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":194}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});