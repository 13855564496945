var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<template id=\"view-user-card-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"U",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":32}}}))
    + "\n</template>\n<template id=\"view-bot-card-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View bot card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"U",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":7,"column":32}}}))
    + "\n</template>\n<template id=\"scroll-to-bottom-button-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Scroll to bottom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":4},"end":{"line":10,"column":29}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"End",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":11,"column":34}}}))
    + "\n</template>\n<template id=\"compose_reply_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":14,"column":38}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"R",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":15,"column":32}}}))
    + "\n</template>\n<template id=\"compose_reply_selected_topic_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Reply to selected conversation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":43}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"R",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":19,"column":32}}}))
    + "\n</template>\n<template id=\"compose_reply_button_disabled_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"You are not allowed to send direct messages in this organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":4},"end":{"line":22,"column":78}}}))
    + "\n</template>\n<template id=\"left_bar_compose_mobile_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":4},"end":{"line":25,"column":24}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":26,"column":32}}}))
    + "\n</template>\n<template id=\"new_topic_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":29,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":30,"column":32}}}))
    + "\n</template>\n<template id=\"new_stream_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New stream message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":4},"end":{"line":33,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"C",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":34,"column":32}}}))
    + "\n</template>\n<template id=\"new_direct_message_button_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"New direct message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"X",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":38,"column":32}}}))
    + "\n</template>\n<template id=\"compose_close_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":4},"end":{"line":41,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":42,"column":4},"end":{"line":42,"column":34}}}))
    + "\n</template>\n<template id=\"compose_close_and_save_tooltip_template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel compose and save draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":4},"end":{"line":45,"column":42}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Esc",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":46,"column":4},"end":{"line":46,"column":34}}}))
    + "\n</template>\n<template id=\"send-enter-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":4},"end":{"line":49,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":50,"column":4},"end":{"line":50,"column":36}}}))
    + "\n</template>\n<template id=\"send-ctrl-enter-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Send",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":4},"end":{"line":53,"column":17}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":54,"column":43}}}))
    + "\n</template>\n<template id=\"add-global-time-tooltip\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add global time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":58,"column":13},"end":{"line":58,"column":37}}}))
    + "</div>\n        <div class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Everyone sees global times in their own time zone.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":50},"end":{"line":59,"column":109}}}))
    + "</div>\n    </div>\n</template>\n<template id=\"add-poll-tooltip\">\n    <div>\n        <span>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add poll",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":64,"column":14},"end":{"line":64,"column":31}}}))
    + "</span><br/>\n        <span class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"A poll must be an entire message.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":51},"end":{"line":65,"column":93}}}))
    + "</span>\n    </div>\n</template>\n<template id=\"delete-draft-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":4},"end":{"line":69,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Backspace",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":70,"column":4},"end":{"line":70,"column":40}}}))
    + "\n</template>\n<template id=\"restore-draft-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Restore draft",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":73,"column":4},"end":{"line":73,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":74,"column":4},"end":{"line":74,"column":36}}}))
    + "\n</template>\n<template id=\"gear-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Main menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":4},"end":{"line":77,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":78,"column":4},"end":{"line":78,"column":32}}}))
    + "\n</template>\n<template id=\"personal-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Personal menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":4},"end":{"line":81,"column":26}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G","→",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":82,"column":4},"end":{"line":82,"column":36}}}))
    + "\n</template>\n<template id=\"help-menu-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help menu",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":85,"column":4},"end":{"line":85,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"G","←",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":86,"column":4},"end":{"line":86,"column":36}}}))
    + "\n</template>\n<template id=\"automatic-theme-template\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Automatic theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":13},"end":{"line":90,"column":37}}}))
    + "</div>\n        <div class=\"tooltip-inner-content italic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Follows system settings.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":91,"column":50},"end":{"line":91,"column":83}}}))
    + "</div>\n    </div>\n</template>\n<template id=\"all-message-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"all_messages\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Combined feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":96,"column":13},"end":{"line":96,"column":35}}}))
    + "</div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":97,"column":84},"end":{"line":97,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"A",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":99,"column":4},"end":{"line":99,"column":32}}}))
    + "\n</template>\n<template id=\"recent-conversations-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"recent_topics\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Recent conversations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":13},"end":{"line":103,"column":42}}}))
    + "</div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":104,"column":84},"end":{"line":104,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"T",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":106,"column":4},"end":{"line":106,"column":32}}}))
    + "\n</template>\n<template id=\"inbox-tooltip-template\">\n    <div class=\"views-tooltip-container\" data-view-code=\"inbox\">\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Inbox",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":13},"end":{"line":110,"column":27}}}))
    + "</div>\n        <div class=\"tooltip-inner-content views-tooltip-home-view-note italic hide\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"This is your home view.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":84},"end":{"line":111,"column":116}}}))
    + "</div>\n    </div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":113,"column":4},"end":{"line":113,"column":40}}}))
    + "\n</template>\n<template id=\"drafts-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":4},"end":{"line":116,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"D",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":117,"column":4},"end":{"line":117,"column":32}}}))
    + "\n</template>\n<template id=\"show-all-pms-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Direct message feed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":4},"end":{"line":120,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","P",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":121,"column":4},"end":{"line":121,"column":40}}}))
    + "\n</template>\n<template id=\"mentions-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mentions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":124,"column":4},"end":{"line":124,"column":21}}}))
    + "\n</template>\n<template id=\"starred-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Starred messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":127,"column":4},"end":{"line":127,"column":29}}}))
    + "\n</template>\n<template id=\"filter-streams-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":4},"end":{"line":130,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Q",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":131,"column":4},"end":{"line":131,"column":32}}}))
    + "\n</template>\n<template id=\"message-expander-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":134,"column":4},"end":{"line":134,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"-",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":135,"column":4},"end":{"line":135,"column":32}}}))
    + "\n</template>\n<template id=\"message-condenser-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show less",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":138,"column":4},"end":{"line":138,"column":22}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"-",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":139,"column":4},"end":{"line":139,"column":32}}}))
    + "\n</template>\n<template id=\"edit-content-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":142,"column":4},"end":{"line":142,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"E",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":143,"column":4},"end":{"line":143,"column":32}}}))
    + "\n</template>\n<template id=\"move-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":146,"column":4},"end":{"line":146,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":147,"column":4},"end":{"line":147,"column":32}}}))
    + "\n</template>\n<template id=\"add-emoji-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":150,"column":4},"end":{"line":150,"column":31}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,":",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":151,"column":4},"end":{"line":151,"column":32}}}))
    + "\n</template>\n<template id=\"message-actions-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":154,"column":4},"end":{"line":154,"column":28}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"I",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":155,"column":4},"end":{"line":155,"column":32}}}))
    + "\n</template>\n<template id=\"dismiss-failed-send-button-tooltip-template\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Dismiss failed message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":159,"column":13},"end":{"line":159,"column":44}}}))
    + "</div>\n        <div class=\"italic tooltip-inner-content\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"This content remains saved in your drafts.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":161,"column":12},"end":{"line":161,"column":63}}}))
    + "\n        </div>\n    </div>\n</template>\n<template id=\"slow-send-spinner-tooltip-template\">\n    <div>\n        <div>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Sending…",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":167,"column":13},"end":{"line":167,"column":30}}}))
    + "</div>\n        <div class=\"italic\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"This message will remain saved in your drafts until it is successfully sent.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":169,"column":12},"end":{"line":169,"column":97}}}))
    + "\n        </div>\n    </div>\n</template>\n<template id=\"star-message-tooltip-template\">\n    <div class=\"starred-status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Star this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":174,"column":32},"end":{"line":174,"column":58}}}))
    + "</div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":175,"column":4},"end":{"line":175,"column":39}}}))
    + "\n</template>\n<template id=\"unstar-message-tooltip-template\">\n    <div class=\"starred-status\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unstar this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":178,"column":32},"end":{"line":178,"column":60}}}))
    + "</div>\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Ctrl","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":179,"column":4},"end":{"line":179,"column":39}}}))
    + "\n</template>\n<template id=\"search-query-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":182,"column":4},"end":{"line":182,"column":19}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"/",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":183,"column":4},"end":{"line":183,"column":32}}}))
    + "\n</template>\n<template id=\"streamlist-toggle-tooltip-template\" >\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":186,"column":4},"end":{"line":186,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Q",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":187,"column":4},"end":{"line":187,"column":32}}}))
    + "\n</template>\n<template id=\"show-userlist-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Show user list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":190,"column":4},"end":{"line":190,"column":27}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"W",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":191,"column":4},"end":{"line":191,"column":32}}}))
    + "\n</template>\n<template id=\"hide-userlist-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hide user list",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":194,"column":4},"end":{"line":194,"column":27}}}))
    + "\n</template>\n<template id=\"topic-unmute-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Unmute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":197,"column":4},"end":{"line":197,"column":25}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":198,"column":4},"end":{"line":198,"column":40}}}))
    + "\n</template>\n<template id=\"topic-mute-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mute topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":201,"column":4},"end":{"line":201,"column":23}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","M",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":202,"column":4},"end":{"line":202,"column":40}}}))
    + "\n</template>\n<template id=\"restore-scheduled-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Edit and reschedule message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":205,"column":4},"end":{"line":205,"column":40}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Enter",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":206,"column":4},"end":{"line":206,"column":36}}}))
    + "\n</template>\n<template id=\"delete-scheduled-message-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete scheduled message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":209,"column":4},"end":{"line":209,"column":37}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Backspace",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":210,"column":4},"end":{"line":210,"column":40}}}))
    + "\n</template>\n<template id=\"create-new-stream-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create new stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":213,"column":4},"end":{"line":213,"column":30}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"N",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":214,"column":4},"end":{"line":214,"column":32}}}))
    + "\n</template>\n<template id=\"toggle-subscription-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Toggle subscription",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":217,"column":4},"end":{"line":217,"column":32}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","S",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":218,"column":4},"end":{"line":218,"column":40}}}))
    + "\n</template>\n<template id=\"view-stream-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"View stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":221,"column":4},"end":{"line":221,"column":24}}}))
    + "\n    "
    + alias2(lookupProperty(helpers,"tooltip_hotkey_hints").call(alias1,"Shift","V",{"name":"tooltip_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":222,"column":4},"end":{"line":222,"column":40}}}))
    + "\n</template>\n<template id=\"mobile-push-notification-tooltip-template\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile push notifications are not enabled on this server.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":225,"column":4},"end":{"line":225,"column":70}}}))
    + "\n</template>\n";
},"useData":true});