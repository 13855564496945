var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"tip\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You can only view or manage invitations that you sent.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":84}}}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th data-sort=\"alphabetic\" data-sort-prop=\"referrer_name\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invited by",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":74},"end":{"line":19,"column":93}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-invites-list\" class=\"settings-section\" data-name=\"invites-list-admin\">\n    <div class=\"tip invite-user-settings-tip\"></div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "    <a class=\"invite-user-link\" role=\"button\"><i class=\"fa fa-user-plus\" aria-hidden=\"true\"></i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invite users to organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":96},"end":{"line":6,"column":133}}}))
    + "</a>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invites",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":9,"column":27}}}))
    + "</h3>\n        <div class=\"alert-notification\" id=\"invites-field-status\"></div>\n        <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter invites",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":73},"end":{"line":11,"column":96}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter invites",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":110},"end":{"line":11,"column":133}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-striped\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"invitee\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invitee",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":55},"end":{"line":17,"column":71}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "                <th data-sort=\"numeric\" data-sort-prop=\"invited\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invited at",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":65},"end":{"line":21,"column":84}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"expiry_date\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expires at",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":69},"end":{"line":22,"column":88}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"invited_as\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Invited as",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":68},"end":{"line":23,"column":87}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":36},"end":{"line":24,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"admin_invites_table\" class=\"admin_invites_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no invites.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":84},"end":{"line":26,"column":114}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No invites match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":143},"end":{"line":26,"column":189}}}))
    + "\"></tbody>\n        </table>\n    </div>\n    <div id=\"admin_page_invites_loading_indicator\"></div>\n</div>\n";
},"useData":true});