var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"user-card-popover-title no-auto-hide-right-sidebar-overlay\"></div>\n<div class=\"user-card-popover-content no-auto-hide-right-sidebar-overlay\">\n    <ul class=\"nav nav-list user-card-popover-actions\" id=\"user_card_popover\" data-user-id=\""
    + alias4((((helper = lookupProperty(helpers,"user_id") || alias1(depth0, "user_id", {"start":{"line":3,"column":94},"end":{"line":3,"column":101}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":92},"end":{"line":3,"column":103}}}) : helper)))
    + "\">\n        <li class=\"popover_user_name_row\">\n            <b class=\"user_full_name\" data-tippy-content=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Unknown user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":79}}}))
    + "\">"
    + alias4(lookupProperty(helpers,"t").call(alias2,"Unknown user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":81},"end":{"line":5,"column":102}}}))
    + "</b>\n        </li>\n        <hr/>\n        <li>\n            <a href=\""
    + alias4((((helper = lookupProperty(helpers,"sent_by_url") || alias1(depth0, "sent_by_url", {"start":{"line":9,"column":24},"end":{"line":9,"column":35}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"sent_by_url","hash":{},"data":data,"loc":{"start":{"line":9,"column":21},"end":{"line":9,"column":38}}}) : helper)))
    + "\" class=\"narrow_to_messages_sent\">\n                <i class=\"fa fa-paper-plane\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"View messages sent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":69},"end":{"line":10,"column":96}}}))
    + "\n            </a>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});