var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "banner-contains-button";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div\n  class=\"main-view-banner success message_scheduled_success_compose_banner\"\n  data-scheduled-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"scheduled_message_id") || container.strict(depth0, "scheduled_message_id", {"start":{"line":3,"column":31},"end":{"line":3,"column":51}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"scheduled_message_id","hash":{},"data":data,"loc":{"start":{"line":3,"column":29},"end":{"line":3,"column":53}}}) : helper)))
    + "\">\n    <div class=\"main-view-banner-elements-wrapper "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"button_text"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":50},"end":{"line":4,"column":98}}})) != null ? stack1 : "")
    + "\">\n        <p class=\"banner_content\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Your message has been scheduled for {deliver_at}.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":34},"end":{"line":5,"column":92}}}))
    + "\n            <a href=\"#scheduled\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":65}}}))
    + "</a>\n        </p>\n        <button class=\"main-view-banner-action-button undo_scheduled_message\" >"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Undo",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":79},"end":{"line":8,"column":91}}}))
    + "</button>\n    </div>\n    <a role=\"button\" class=\"zulip-icon zulip-icon-close main-view-banner-close-button\"></a>\n</div>\n";
},"useData":true});