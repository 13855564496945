var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li>\n        <label class=\"checkbox\">\n            <div>\n                <input type=\"checkbox\" class=\"task\" data-key=\""
    + container.escapeExpression((((helper = lookupProperty(helpers,"key") || container.strict(depth0, "key", {"start":{"line":5,"column":65},"end":{"line":5,"column":68}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"key","hash":{},"data":data,"loc":{"start":{"line":5,"column":62},"end":{"line":5,"column":71}}}) : helper)))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"completed"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":73},"end":{"line":5,"column":104}}})) != null ? stack1 : "")
    + "/>\n                <span></span>\n            </div>\n            <div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"completed"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":9,"column":16},"end":{"line":13,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n        </label>\n    </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <strike><strong>"
    + container.escapeExpression((((helper = lookupProperty(helpers,"task") || container.strict(depth0, "task", {"start":{"line":10,"column":35},"end":{"line":10,"column":39}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"task","hash":{},"data":data,"loc":{"start":{"line":10,"column":32},"end":{"line":10,"column":42}}}) : helper)))
    + "</strong>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"desc"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":83}}})) != null ? stack1 : "")
    + "</strike>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ": "
    + container.escapeExpression((((helper = lookupProperty(helpers,"desc") || container.strict(depth0, "desc", {"start":{"line":10,"column":69},"end":{"line":10,"column":73}} ))),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"desc","hash":{},"data":data,"loc":{"start":{"line":10,"column":66},"end":{"line":10,"column":76}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <strong>"
    + container.escapeExpression((((helper = lookupProperty(helpers,"task") || container.strict(depth0, "task", {"start":{"line":12,"column":27},"end":{"line":12,"column":31}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"task","hash":{},"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":34}}}) : helper)))
    + "</strong>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"desc"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":43},"end":{"line":12,"column":75}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"all_tasks"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "");
},"useData":true});