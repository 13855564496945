var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_bot"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":17,"column":27}}})) != null ? stack1 : "")
    + "                    <span>"
    + container.escapeExpression((((helper = lookupProperty(helpers,"full_name") || container.strict(depth0, "full_name", {"start":{"line":18,"column":28},"end":{"line":18,"column":37}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"full_name","hash":{},"data":data,"loc":{"start":{"line":18,"column":26},"end":{"line":18,"column":39}}}) : helper)))
    + "</span>\n                </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "                    <i class=\"zulip-icon zulip-icon-bot\" aria-hidden=\"true\"></i>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"user_circle "
    + alias4((((helper = lookupProperty(helpers,"user_circle_class") || alias1(depth0, "user_circle_class", {"start":{"line":16,"column":47},"end":{"line":16,"column":64}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_circle_class","hash":{},"data":data,"loc":{"start":{"line":16,"column":45},"end":{"line":16,"column":66}}}) : helper)))
    + "  popover_user_presence\" title=\""
    + alias4((((helper = lookupProperty(helpers,"user_last_seen_time_status") || alias1(depth0, "user_last_seen_time_status", {"start":{"line":16,"column":100},"end":{"line":16,"column":126}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"user_last_seen_time_status","hash":{},"data":data,"loc":{"start":{"line":16,"column":98},"end":{"line":16,"column":128}}}) : helper)))
    + "\"></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <hr />\n        <ul class=\"nav nav-list manage-group\">\n            <li>\n                <a href=\""
    + alias2((((helper = lookupProperty(helpers,"group_edit_url") || container.strict(depth0, "group_edit_url", {"start":{"line":26,"column":27},"end":{"line":26,"column":41}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"group_edit_url","hash":{},"data":data,"loc":{"start":{"line":26,"column":25},"end":{"line":26,"column":43}}}) : helper)))
    + "\">\n                    <i class=\"zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":85},"end":{"line":27,"column":108}}}))
    + "\n                </a>\n            </li>\n        </ul>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"group-info-popover\">\n    <div class=\"group-info-content\">\n        <div class=\"group-info\">\n            <div class=\"group-name\"> "
    + alias4((((helper = lookupProperty(helpers,"group_name") || alias1(depth0, "group_name", {"start":{"line":4,"column":39},"end":{"line":4,"column":49}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"group_name","hash":{},"data":data,"loc":{"start":{"line":4,"column":37},"end":{"line":4,"column":51}}}) : helper)))
    + " </div>\n            <div class=\"group-description\">\n                "
    + alias4((((helper = lookupProperty(helpers,"group_description") || alias1(depth0, "group_description", {"start":{"line":6,"column":18},"end":{"line":6,"column":35}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"group_description","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":37}}}) : helper)))
    + "\n            </div>\n        </div>\n        <hr />\n        <ul class=\"nav nav-list member-list\" data-simplebar data-simplebar-auto-hide=\"false\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,lookupProperty(depth0,"members"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":20,"column":21}}})) != null ? stack1 : "")
    + "        </ul>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":8},"end":{"line":31,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});