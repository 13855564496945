var Handlebars = require("../../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " guest-avatar";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-avatar"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_is_guest"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":26},"end":{"line":1,"column":67}}})) != null ? stack1 : "")
    + "\" style=\"background-image: url('"
    + container.escapeExpression((((helper = lookupProperty(helpers,"user_avatar") || container.strict(depth0, "user_avatar", {"start":{"line":1,"column":101},"end":{"line":1,"column":112}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"user_avatar","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":114}}}) : helper)))
    + "');\" >\n</div>\n";
},"useData":true});