var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"giphy_grid_in_popover\">\n    <div class=\"arrow\"></div>\n    <div class=\"popover-inner\">\n        <div class=\"search-box\">\n            <input type=\"text\" tabindex=0 id=\"giphy-search-query\" class=\"search-query\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search GIFs",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":100},"end":{"line":5,"column":120}}}))
    + "\" />\n            <button type=\"button\" class=\"btn clear_search_button\" id=\"giphy_search_clear\">\n                <i class=\"fa fa-remove\" aria-hidden=\"true\"></i>\n            </button>\n        </div>\n        <div class=\"giphy-scrolling-container\" data-simplebar>\n            <div class=\"giphy-content\"></div>\n        </div>\n        <div class=\"popover-footer\">\n            <img src=\"" + require("../images/giphy/GIPHY_attribution.png") + "\" alt=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"GIPHY attribution",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":66},"end":{"line":18,"column":92}}}))
    + "\" />\n        </div>\n    </div>\n</div>\n";
},"useData":true});