var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return alias4((((helper = lookupProperty(helpers,"text_length") || alias1(depth0, "text_length", {"start":{"line":2,"column":2},"end":{"line":2,"column":13}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"text_length","hash":{},"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":2,"column":15}}}) : helper)))
    + "&ZeroWidthSpace;/"
    + alias4((((helper = lookupProperty(helpers,"max_length") || alias1(depth0, "max_length", {"start":{"line":2,"column":34},"end":{"line":2,"column":44}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"max_length","hash":{},"data":data,"loc":{"start":{"line":2,"column":32},"end":{"line":2,"column":46}}}) : helper)))
    + "\n";
},"useData":true});