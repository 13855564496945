var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"message-header-stream-settings-button tippy-zulip-tooltip\" data-tooltip-template-id=\"stream-details-tooltip-template\" data-tippy-placement=\"bottom\" href=\""
    + alias1(container.lambda(container.strict(depth0, "stream_settings_link", {"start":{"line":2,"column":166},"end":{"line":2,"column":186}} ), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("./navbar_icon_and_title.hbs"),depth0,{"name":"navbar_icon_and_title","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>\n<template id=\"stream-details-tooltip-template\">\n    <div>\n        <div>"
    + alias1(lookupProperty(helpers,"t").call(alias2,"Go to stream settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":13},"end":{"line":7,"column":43}}}))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_spectator"),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":12,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</template>\n<span class=\"narrow_description rendered_markdown\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"rendered_narrow_description"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":16,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "</span>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tooltip-inner-content italic\">\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"This stream has {sub_count, plural, =0 {no subscribers} one {# subscriber} other {# subscribers}}.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":119}}}))
    + "\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(lookupProperty(helpers,"rendered_markdown").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"rendered_narrow_description"),{"name":"rendered_markdown","hash":{},"data":data,"loc":{"start":{"line":17,"column":4},"end":{"line":17,"column":53}}}))
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a href=\""
    + alias1(container.lambda(container.strict(depth0, "stream_settings_link", {"start":{"line":20,"column":15},"end":{"line":20,"column":35}} ), depth0))
    + "\">\n        "
    + alias1(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add a description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":21,"column":33}}}))
    + "\n    </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<span class=\"navbar_title\">\n    "
    + ((stack1 = container.invokePartial(require("./navbar_icon_and_title.hbs"),depth0,{"name":"navbar_icon_and_title","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"stream_settings_link"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":30,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});