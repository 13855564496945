var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Search for <z-value></z-value> in the topic or message content.\n                                \n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<span class=\"operator_value\">keyword</span>";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Narrow to messages on stream <z-value></z-value>.\n                                \n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<span class=\"operator_value\">stream</span>";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Narrow to messages with topic <z-value></z-value>.\n                                \n";
},"8":function(container,depth0,helpers,partials,data) {
    return "<span class=\"operator_value\">topic</span>";
},"10":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Narrow to direct messages with <z-value></z-value>.\n                                \n";
},"11":function(container,depth0,helpers,partials,data) {
    return "<span class=\"operator_value\">user</span>";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Narrow to direct messages that include <z-value></z-value>.\n                                \n";
},"15":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Narrow to messages sent by <z-value></z-value>.\n                                \n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Center the view around message ID <z-value></z-value>.\n                                \n";
},"18":function(container,depth0,helpers,partials,data) {
    return "<span class=\"operator_value\">id</span>";
},"20":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Narrow to just message ID <z-value></z-value>.\n                                \n";
},"22":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                Exclude messages with topic <z-value></z-value>.\n                                \n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"overlay-modal hide\" id=\"search-operators\" tabindex=\"-1\" role=\"dialog\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search filters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":94},"end":{"line":1,"column":117}}}))
    + "\">\n    <div class=\"overlay-scroll-container\" data-simplebar data-simplebar-auto-hide=\"false\">\n        <div id=\"operators-instructions\">\n            <table class=\"table table-striped table-rounded table-bordered help-table\">\n                <thead>\n                    <tr>\n                        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":28},"end":{"line":7,"column":43}}}))
    + "</th>\n                        <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Effect",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":43}}}))
    + "</th>\n                    </tr>\n                </thead>\n                <tbody>\n                    <tr>\n                        <td class=\"operator\"><span class=\"operator_value\">keyword</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":18,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">stream:<span class=\"operator_value\">stream</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":28},"end":{"line":27,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">topic:<span class=\"operator_value\">topic</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":36,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">is:dm</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to direct messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":28},"end":{"line":42,"column":62}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">dm:<span class=\"operator_value\">user</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":28},"end":{"line":51,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">dm-including:<span class=\"operator_value\">user</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":57,"column":28},"end":{"line":60,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">streams:public</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search all public streams in the organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":66,"column":28},"end":{"line":66,"column":82}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">sender:<span class=\"operator_value\">user</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":72,"column":28},"end":{"line":75,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">sender:me</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages sent by you.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":28},"end":{"line":81,"column":67}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">has:link</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages containing links.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":87,"column":28},"end":{"line":87,"column":72}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">has:attachment</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages containing uploads.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":28},"end":{"line":93,"column":74}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">has:image</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages containing images.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":99,"column":28},"end":{"line":99,"column":73}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">is:alerted</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages with alert words.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":105,"column":28},"end":{"line":105,"column":72}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">is:mentioned</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages that mention you.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":28},"end":{"line":111,"column":72}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">is:starred</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to starred messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":117,"column":28},"end":{"line":117,"column":63}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">is:resolved</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to messages in resolved topics.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":123,"column":28},"end":{"line":123,"column":74}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">is:unread</td>\n                        <td class=\"definition\">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Narrow to unread messages.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":28},"end":{"line":129,"column":62}}}))
    + "\n                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">near:<span class=\"operator_value\">id</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":135,"column":28},"end":{"line":138,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">id:<span class=\"operator_value\">id</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":28},"end":{"line":147,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                    <tr>\n                        <td class=\"operator\">-topic:<span class=\"operator_value\">topic</span></td>\n                        <td class=\"definition\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":28},"end":{"line":156,"column":35}}})) != null ? stack1 : "")
    + "                        </td>\n                    </tr>\n                </tbody>\n            </table>\n            <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"You can combine search filters as needed.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":161,"column":15},"end":{"line":161,"column":65}}}))
    + "</p>\n            <hr />\n            <a href=\"help/search-for-messages#search-filters\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Detailed search filters documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":163,"column":104},"end":{"line":163,"column":150}}}))
    + "</a>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":17,"column":32},"end":{"line":17,"column":108}}}) || fn;
  return fn;
  }

,"useDecorators":true,"4_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":107}}}) || fn;
  return fn;
  }

,"7_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":35,"column":32},"end":{"line":35,"column":106}}}) || fn;
  return fn;
  }

,"10_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":50,"column":32},"end":{"line":50,"column":105}}}) || fn;
  return fn;
  }

,"13_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":59,"column":32},"end":{"line":59,"column":105}}}) || fn;
  return fn;
  }

,"15_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":74,"column":32},"end":{"line":74,"column":105}}}) || fn;
  return fn;
  }

,"17_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":137,"column":32},"end":{"line":137,"column":103}}}) || fn;
  return fn;
  }

,"20_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":146,"column":32},"end":{"line":146,"column":103}}}) || fn;
  return fn;
  }

,"22_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-value"],"data":data,"loc":{"start":{"line":155,"column":32},"end":{"line":155,"column":106}}}) || fn;
  return fn;
  }

,"useData":true,"useDepths":true});