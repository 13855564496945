var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " checked";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <z-shortcut></z-shortcut> to send\n                            \n";
},"4":function(container,depth0,helpers,partials,data) {
    return "<kbd>Enter</kbd>";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <z-shortcut></z-shortcut> to add a new line\n                            \n";
},"7":function(container,depth0,helpers,partials,data) {
    return "<kbd>Ctrl</kbd>+<kbd>Enter</kbd>";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <z-shortcut></z-shortcut> to send\n                            \n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <z-shortcut></z-shortcut> to add a new line\n                            \n";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li>\n            <a class=\"send_later_selected_send_later_time\" tabindex=\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Schedule for {formatted_send_later_time}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":72},"end":{"line":46,"column":121}}}))
    + "</a>\n        </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <hr />\n    <li>\n        <a class=\"compose_new_message\" tabindex==\"0\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Save draft and start a new message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":53},"end":{"line":59,"column":96}}}))
    + "</a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul id=\"send_later_popover\" class=\"nav nav-list\">\n    <li>\n        <div class=\"enter_sends_choices grey-box\">\n            <label class=\"enter_sends_choice\">\n                <input type=\"radio\" name=\"enter_sends_choice\" class=\"prop-element\" value=\"true\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"enter_sends_true"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":95},"end":{"line":5,"column":135}}})) != null ? stack1 : "")
    + " />\n                <div class=\"enter_sends_choice_text\">\n                    <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":24},"end":{"line":11,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                    <span class=\"enter_sends_minor\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":24},"end":{"line":17,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </div>\n            </label>\n            <label class=\"enter_sends_choice\">\n                <input type=\"radio\" name=\"enter_sends_choice\" class=\"prop-element\" value=\"false\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"enter_sends_true"),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":96},"end":{"line":22,"column":144}}})) != null ? stack1 : "")
    + " />\n                <div class=\"enter_sends_choice_text\">\n                    <span>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":28,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                    <span class=\"enter_sends_minor\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":24},"end":{"line":34,"column":31}}})) != null ? stack1 : "")
    + "                    </span>\n                </div>\n            </label>\n        </div>\n    </li>\n    <hr />\n    <li>\n        <a class=\"open_send_later_modal\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Schedule message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":54},"end":{"line":42,"column":79}}}))
    + "</a>\n    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"formatted_send_later_time"),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":48,"column":11}}})) != null ? stack1 : "")
    + "    <li>\n        <a href=\"#scheduled\" class=\"navigate-link-on-enter\" tabindex=\"0\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":73},"end":{"line":50,"column":105}}}))
    + "</a>\n    </li>\n    <hr class=\"drafts-item-in-popover\" />\n    <li class=\"drafts-item-in-popover\">\n        <a href=\"#drafts\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":26},"end":{"line":54,"column":41}}}))
    + " <span class=\"compose-drafts-count-container\">(<span class=\"compose-drafts-count\"></span>)</span></a>\n    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_compose_new_message"),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":61,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"3_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":10,"column":28},"end":{"line":10,"column":80}}}) || fn;
  return fn;
  }

,"useDecorators":true,"6_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":16,"column":28},"end":{"line":16,"column":96}}}) || fn;
  return fn;
  }

,"9_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":27,"column":28},"end":{"line":27,"column":96}}}) || fn;
  return fn;
  }

,"11_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-shortcut"],"data":data,"loc":{"start":{"line":33,"column":28},"end":{"line":33,"column":80}}}) || fn;
  return fn;
  }

,"useData":true,"useDepths":true});