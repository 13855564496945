var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"alert-word-item\" data-word='"
    + alias4((((helper = lookupProperty(helpers,"word") || alias1(depth0, "word", {"start":{"line":3,"column":41},"end":{"line":3,"column":45}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"word","hash":{},"data":data,"loc":{"start":{"line":3,"column":39},"end":{"line":3,"column":47}}}) : helper)))
    + "'>\n    <td>\n        <div class=\"alert_word_listing\">\n            <span class=\"value\">"
    + alias4((((helper = lookupProperty(helpers,"word") || alias1(depth0, "word", {"start":{"line":6,"column":34},"end":{"line":6,"column":38}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"word","hash":{},"data":data,"loc":{"start":{"line":6,"column":32},"end":{"line":6,"column":40}}}) : helper)))
    + "</span>\n        </div>\n    </td>\n    <td>\n        <button type=\"submit\" class=\"button rounded small delete btn-danger remove-alert-word\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete alert word",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":102},"end":{"line":10,"column":128}}}))
    + "\" data-word=\""
    + alias4((((helper = lookupProperty(helpers,"word") || alias1(depth0, "word", {"start":{"line":10,"column":143},"end":{"line":10,"column":147}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"word","hash":{},"data":data,"loc":{"start":{"line":10,"column":141},"end":{"line":10,"column":149}}}) : helper)))
    + "\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n        </button>\n    </td>\n</tr>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"alert_word"),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":15,"column":9}}})) != null ? stack1 : "");
},"useData":true});