var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "style=\"display:none\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"member_list_settings_container\">\n    <h4 class=\"user_group_setting_subsection_title\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":28}}}))
    + "\n    </h4>\n    <div class=\"member_list_settings\">\n        <div class=\"member_list_add float-left\">\n            "
    + ((stack1 = container.invokePartial(require("./add_members_form.hbs"),depth0,{"name":"add_members_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"user_group_subscription_request_result\"></div>\n        </div>\n        <div class=\"clear-float\"></div>\n    </div>\n    <div>\n        <h4 class=\"inline-block user_group_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":69},"end":{"line":13,"column":84}}}))
    + "</h4>\n        <span class=\"member-search float-right\">\n            <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter members",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":77},"end":{"line":15,"column":100}}}))
    + "\" />\n        </span>\n    </div>\n    <div class=\"member-list-box\">\n        <div class=\"member_list_container\" data-simplebar>\n            <div class=\"member_list_loading_indicator\"></div>\n            <table class=\"member-list table table-striped\">\n                <thead class=\"table-sticky-headers\">\n                    <th data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":74},"end":{"line":23,"column":87}}}))
    + "</th>\n                    <th data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":56}}}))
    + "</th>\n                    <th class=\"user-remove-actions\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_edit"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":52},"end":{"line":25,"column":103}}})) != null ? stack1 : "")
    + ">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":104},"end":{"line":25,"column":120}}}))
    + "</th>\n                </thead>\n                <tbody class=\"member_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This group has no members.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":91}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No group members match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":120},"end":{"line":27,"column":171}}}))
    + "\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});