var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr class=\"linkifier_row"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depths[1],"can_modify"),lookupProperty(depths[1],"can_drag"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":2,"column":30},"end":{"line":2,"column":61}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":82}}})) != null ? stack1 : "")
    + "\" data-linkifier-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias2(depth0, "id", {"start":{"line":2,"column":105},"end":{"line":2,"column":107}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":103},"end":{"line":2,"column":109}}}) : helper)))
    + "\">\n    <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"and").call(alias1,lookupProperty(depths[1],"can_modify"),lookupProperty(depths[1],"can_drag"),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":45}}}),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":9,"column":15}}})) != null ? stack1 : "")
    + "        <span class=\"linkifier_pattern\">"
    + alias4((((helper = lookupProperty(helpers,"pattern") || alias2(depth0, "pattern", {"start":{"line":10,"column":42},"end":{"line":10,"column":49}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"pattern","hash":{},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":51}}}) : helper)))
    + "</span>\n    </td>\n    <td>\n        <span class=\"linkifier_url_template\">"
    + alias4((((helper = lookupProperty(helpers,"url_template") || alias2(depth0, "url_template", {"start":{"line":13,"column":47},"end":{"line":13,"column":59}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"url_template","hash":{},"data":data,"loc":{"start":{"line":13,"column":45},"end":{"line":13,"column":61}}}) : helper)))
    + "</span>\n    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depths[1],"can_modify"),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "</tr>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " movable-row";
},"4":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"move-handle\">\n                <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n                <i class=\"fa fa-ellipsis-v\" aria-hidden=\"true\"></i>\n            </span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <td class=\"no-select actions\">\n        <button class=\"button small edit btn-warning\" data-linkifier-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":17,"column":75},"end":{"line":17,"column":77}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":17,"column":73},"end":{"line":17,"column":79}}}) : helper)))
    + "\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":88},"end":{"line":17,"column":101}}}))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Edit",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":115},"end":{"line":17,"column":128}}}))
    + "\">\n            <i class=\"fa fa-pencil\"></i>\n        </button>\n        <button class=\"button small delete btn-danger\" data-linkifier-id=\""
    + alias4((((helper = lookupProperty(helpers,"id") || alias1(depth0, "id", {"start":{"line":20,"column":76},"end":{"line":20,"column":78}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":20,"column":74},"end":{"line":20,"column":80}}}) : helper)))
    + "\" title=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":89},"end":{"line":20,"column":104}}}))
    + "\" aria-label=\""
    + alias4(lookupProperty(helpers,"t").call(alias2,"Delete",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":118},"end":{"line":20,"column":133}}}))
    + "\">\n            <i class=\"fa fa-trash-o\"></i>\n        </button>\n    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"linkifier"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":26,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});