var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a href=\"/help/contact-support\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-life-buoy\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Contact support",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":57},"end":{"line":40,"column":81}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"help-menu-dropdown\" aria-labelledby=\"help-menu\" data-simplebar>\n    <ul class=\"popover-menu-outer-list\">\n        <li class=\"popover-menu-outer-list-item\">\n            <ul class=\"popover-menu-inner-list\">\n                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a href=\"/help/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-help\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Help center",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":57},"end":{"line":8,"column":77}}}))
    + "</span>\n                    </a>\n                </li>\n                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter popover-menu-link\" data-overlay-trigger=\"keyboard-shortcuts\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-keyboard\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Keyboard shortcuts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":57},"end":{"line":14,"column":84}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,"?",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":15,"column":24},"end":{"line":15,"column":52}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item popover-menu-inner-list-item hidden-for-spectators\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter popover-menu-link\" data-overlay-trigger=\"message-formatting\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message formatting",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":57},"end":{"line":21,"column":84}}}))
    + "</span>\n                    </a>\n                </li>\n                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter popover-menu-link\" data-overlay-trigger=\"search-operators\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-manage-search\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Search filters",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":57},"end":{"line":27,"column":80}}}))
    + "</span>\n                    </a>\n                </li>\n                <li class=\"link-item popover-menu-inner-list-item\" id=\"gear_menu_about_zulip\">\n                    <a href=\"#about-zulip\" class=\"navigate-link-on-enter popover-menu-link\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-info\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"About Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":57},"end":{"line":33,"column":77}}}))
    + "</span>\n                    </a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"corporate_enabled"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":16},"end":{"line":43,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});