var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list\">\n    <li>\n        <a tabindex=\"0\" id=\"delete_all_drafts_sidebar\">\n            <i class=\"fa fa-trash-o\" aria-hidden=\"true\"></i>\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Delete all drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":38}}}))
    + "\n        </a>\n    </li>\n</ul>\n";
},"useData":true});