var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <z-link>Click here</z-link> to learn about exporting private streams and direct messages.\n            \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/export-your-organization\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"alert\" id=\"export_status\" role=\"alert\">\n        <span class=\"export_status_text\"></span>\n    </div>\n    <form>\n        <button type=\"submit\" class=\"button rounded sea-green\" id=\"export-data\">\n            "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Start export of public data",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":24,"column":48}}}))
    + "\n        </button>\n    </form>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"data-exports\" class=\"settings-section\" data-name=\"data-exports-admin\">\n    <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Export organization",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":36}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/export-your-organization"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </h3>\n    <p>\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Exports all users, settings, and all data visible in public streams.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":85}}}))
    + "\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Any organization administrator can conduct an export.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":8},"end":{"line":7,"column":69}}}))
    + "\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Depending on the size of your organization, an export can take anywhere from seconds to an hour.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":8,"column":113}}}))
    + "\n    </p>\n    <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":14,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Note that organizations are limited to five exports per week.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":15,"column":78}}}))
    + "\n    </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "")
    + "\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Data exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":12},"end":{"line":30,"column":32}}}))
    + "</h3>\n        <input type=\"hidden\" class=\"search\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":57},"end":{"line":31,"column":80}}}))
    + "\"\n          aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter exports",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":22},"end":{"line":32,"column":45}}}))
    + "\"/>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-striped wrapped-table admin_exports_table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"user\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Requesting user",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":52},"end":{"line":38,"column":76}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"export_time\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Time",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":39,"column":69},"end":{"line":39,"column":82}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":40,"column":35}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":41,"column":36},"end":{"line":41,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"admin_exports_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no exports.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":56},"end":{"line":43,"column":86}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":151}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});