var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"has_image"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":6,"column":11}}})) != null ? stack1 : "")
    + "    &nbsp;&nbsp;\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "        <img class=\"emoji\" src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "img_src", {"start":{"line":3,"column":35},"end":{"line":3,"column":42}} ), depth0))
    + "\" />\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "        <span class='emoji emoji-"
    + container.escapeExpression(container.lambda(container.strict(depth0, "emoji_code", {"start":{"line":5,"column":36},"end":{"line":5,"column":46}} ), depth0))
    + "'></span>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_person"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":8,"column":0},"end":{"line":19,"column":0}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"user_circle_class"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_image"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":16,"column":11}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "    <span class=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "user_circle_class", {"start":{"line":10,"column":19},"end":{"line":10,"column":36}} ), depth0))
    + " user_circle\"></span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <img class=\"typeahead-image\" src=\""
    + container.escapeExpression(container.lambda(container.strict(depth0, "img_src", {"start":{"line":13,"column":41},"end":{"line":13,"column":48}} ), depth0))
    + "\" />\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <span class='typeahead-image fa fa-bullhorn no-presence-circle'></span>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_user_group"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":0},"end":{"line":19,"column":0}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "    <i class=\"typeahead-image icon fa fa-group no-presence-circle\" aria-hidden=\"true\"></i>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = container.invokePartial(require("./inline_decorated_stream_name.hbs"),depth0,{"name":"inline_decorated_stream_name","hash":{"stream":lookupProperty(depth0,"stream")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(container.strict(depth0, "primary", {"start":{"line":24,"column":12},"end":{"line":24,"column":19}} ), depth0));
},"21":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"pronouns\">("
    + container.escapeExpression(container.lambda(container.strict(depth0, "pronouns", {"start":{"line":28,"column":30},"end":{"line":28,"column":38}} ), depth0))
    + ")</span>";
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <i>("
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"guest",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":21}}}))
    + ")</i>";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("./status_emoji.hbs"),lookupProperty(depth0,"status_emoji_info"),{"name":"status_emoji","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;&nbsp;\n<small class=\"autocomplete_secondary\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "secondary", {"start":{"line":39,"column":8},"end":{"line":39,"column":17}} ), depth0))
    + "</small>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_unsubscribed"),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":0},"end":{"line":45,"column":7}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "&nbsp;\n<span class=\"fa fa-exclamation-triangle unsubscribed_icon\"\n  title=\""
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"You are not currently subscribed to this stream.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":9},"end":{"line":44,"column":66}}}))
    + "\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_emoji"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":19,"column":7}}})) != null ? stack1 : "")
    + "<strong>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"stream"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":25,"column":11}}})) != null ? stack1 : "")
    + "</strong>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_pronouns"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":29,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_add_guest_user_indicator"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":0},"end":{"line":32,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_status"),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":35,"column":8}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_secondary"),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":0},"end":{"line":46,"column":8}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});