var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"emoji-settings\" data-name=\"emoji-settings\" class=\"settings-section\">\n    <div class=\"emoji-settings-tip-container "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_admin"),lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"can_add_emojis"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":2,"column":68},"end":{"line":2,"column":88}}}),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":2,"column":55},"end":{"line":2,"column":89}}}),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":45},"end":{"line":2,"column":106}}})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = container.invokePartial(require("./emoji_settings_tip.hbs"),depth0,{"name":"emoji_settings_tip","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <p class=\"add-emoji-text "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_add_emojis"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":29},"end":{"line":5,"column":70}}})) != null ? stack1 : "")
    + "\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add extra emoji for members of the {realm_name} organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":78}}}))
    + "\n    </p>\n    <button id=\"add-custom-emoji-button\" class=\"button rounded sea-green "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"can_add_emojis"),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":73},"end":{"line":8,"column":114}}})) != null ? stack1 : "")
    + "\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":32}}}))
    + "\n    </button>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":12},"end":{"line":13,"column":32}}}))
    + "</h3>\n        <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":73},"end":{"line":14,"column":94}}}))
    + "\"\n          aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter emoji",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":22},"end":{"line":15,"column":43}}}))
    + "\"/>\n    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-striped wrapped-table admin_emoji_table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":80},"end":{"line":20,"column":93}}}))
    + "</th>\n                <th class=\"image\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Image",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":34},"end":{"line":21,"column":48}}}))
    + "</th>\n                <th class=\"image\" data-sort=\"author_full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Author",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":63},"end":{"line":22,"column":78}}}))
    + "</th>\n                <th class=\"actions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":36},"end":{"line":23,"column":52}}}))
    + "</th>\n            </thead>\n            <tbody id=\"admin_emoji_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no custom emoji.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":54},"end":{"line":25,"column":89}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No custom emojis match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":118},"end":{"line":25,"column":170}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});