var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"name-setting profile-field-form new-style\" id=\"edit-custom-profile-field-form-"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":2,"column":93},"end":{"line":2,"column":95}} ), depth0))
    + "\">\n    <div class=\"input-group\">\n        <label for=\"name\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":40}}}))
    + "</label>\n        <input type=\"text\" name=\"name\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":5,"column":74},"end":{"line":5,"column":78}} ), depth0))
    + "\" maxlength=\"40\" />\n    </div>\n    <div class=\"input-group hint_change_container\">\n        <label for=\"hint\">"
    + alias3(lookupProperty(helpers,"t").call(alias4,"Hint",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":26},"end":{"line":8,"column":39}}}))
    + "</label>\n        <input type=\"text\" name=\"hint\" class=\"modal_text_input\" value=\""
    + alias3(alias2(alias1(depth0, "hint", {"start":{"line":9,"column":74},"end":{"line":9,"column":78}} ), depth0))
    + "\" maxlength=\"80\" />\n    </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_select_field"),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"is_external_account_field"),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"valid_to_display_in_summary"),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":4},"end":{"line":47,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"input-group\">\n        <label class=\"checkbox\" for=\"edit-required-"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":49,"column":53},"end":{"line":49,"column":55}} ), depth0))
    + "\">\n            <input class=\"edit-required\" type=\"checkbox\" id=\"edit-required-"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":50,"column":77},"end":{"line":50,"column":79}} ), depth0))
    + "\" name=\"required\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"required"),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":99},"end":{"line":50,"column":141}}})) != null ? stack1 : "")
    + "/>\n            <span></span>\n            "
    + alias3(lookupProperty(helpers,"t").call(alias4,"Required field",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":12},"end":{"line":52,"column":35}}}))
    + "\n        </label>\n    </div>\n</form>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"input-group\">\n        <label for=\"profile_field_choices_edit\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Field choices",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":48},"end":{"line":13,"column":70}}}))
    + "</label>\n        <div class=\"profile-field-choices\" name=\"profile_field_choices_edit\">\n            <hr />\n            <div class=\"edit_profile_field_choices_container\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"choices"),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":19,"column":25}}})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = container.invokePartial(require("./profile_field_choice.hbs"),depth0,{"name":"profile_field_choice","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"input-group profile_field_external_accounts_edit\">\n        <label for=\"external_acc_field_type\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"External account type",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":45},"end":{"line":26,"column":75}}}))
    + "</label>\n        <select name=\"external_acc_field_type\" class=\"modal_select\" disabled>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depths[1],"realm_default_external_accounts"),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":12},"end":{"line":30,"column":21}}})) != null ? stack1 : "")
    + "            <option value=\"custom\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":35},"end":{"line":31,"column":50}}}))
    + "</option>\n        </select>\n    </div>\n    <div class=\"input-group custom_external_account_detail\">\n        <label for=\"url_pattern\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":33},"end":{"line":35,"column":53}}}))
    + "</label>\n        <input type=\"url\" class=\"modal_url_input\" name=\"url_pattern\" autocomplete=\"off\" maxlength=\"80\" />\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                <option value='"
    + alias3(alias2(alias1(data, "key", {"start":{"line":29,"column":33},"end":{"line":29,"column":37}} ), depth0))
    + "'>"
    + alias3(alias2(alias1(depth0, "text", {"start":{"line":29,"column":43},"end":{"line":29,"column":52}} ), depth0))
    + "</option>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group\">\n            <label class=\"checkbox edit_profile_field_display_label\" for=\"edit_display_in_profile_summary_"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":41,"column":108},"end":{"line":41,"column":110}} ), depth0))
    + "\">\n                <input class=\"edit_display_in_profile_summary\" type=\"checkbox\" id=\"edit_display_in_profile_summary_"
    + alias3(alias2(alias1(depth0, "id", {"start":{"line":42,"column":117},"end":{"line":42,"column":119}} ), depth0))
    + "\" name=\"display_in_profile_summary\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(depth0,"display_in_profile_summary"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":157},"end":{"line":42,"column":217}}})) != null ? stack1 : "")
    + "/>\n                <span></span>\n                "
    + alias3(lookupProperty(helpers,"t").call(alias4,"Display on user card",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":16},"end":{"line":44,"column":45}}}))
    + "\n            </label>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\" ";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"profile_field_info"),{"name":"with","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":56,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});