var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a class=\"tippy-zulip-delayed-tooltip\" data-tooltip-template-id=\"view-stream-tooltip-template\" data-tippy-placement=\"top\" href=\""
    + alias3(alias2(alias1(depth0, "preview_url", {"start":{"line":1,"column":130},"end":{"line":1,"column":141}} ), depth0))
    + "\">\n    "
    + ((stack1 = container.invokePartial(require("./stream_privacy_icon.hbs"),depth0,{"name":"stream_privacy_icon","hash":{"color":lookupProperty(depth0,"title_icon_color"),"is_web_public":lookupProperty(lookupProperty(depth0,"sub"),"is_web_public"),"invite_only":lookupProperty(lookupProperty(depth0,"sub"),"invite_only")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    <span class=\"stream-name-title\">"
    + alias3(alias2(alias1(lookupProperty(depth0,"sub"), "name", {"start":{"line":6,"column":38},"end":{"line":6,"column":46}} ), depth0))
    + "</span>\n</a>\n";
},"usePartial":true,"useData":true});