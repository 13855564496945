var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active-sub-filter";
},"3":function(container,depth0,helpers,partials,data) {
    return "zero-topic-unreads";
},"5":function(container,depth0,helpers,partials,data) {
    return "muted_topic";
},"7":function(container,depth0,helpers,partials,data) {
    return "unmuted_or_followed_topic";
},"9":function(container,depth0,helpers,partials,data) {
    return "                <span class=\"unread_mention_info\">\n                    @\n                </span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_followed"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":16,"column":12}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    return "                <i class=\"zulip-icon zulip-icon-follow\" aria-hidden=\"true\"></i>\n            ";
},"14":function(container,depth0,helpers,partials,data) {
    return "zero_count";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<li class=\"bottom_left_row "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_active_topic"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":27},"end":{"line":1,"column":74}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_zero"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":75},"end":{"line":1,"column":115}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_muted"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":116},"end":{"line":1,"column":150}}})) != null ? stack1 : "")
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_unmuted_or_followed"),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":151},"end":{"line":1,"column":213}}})) != null ? stack1 : "")
    + " topic-list-item\" data-topic-name=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias2(depth0, "topic_name", {"start":{"line":1,"column":250},"end":{"line":1,"column":260}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":248},"end":{"line":1,"column":262}}}) : helper)))
    + "\">\n    <div class=\"topic-box\">\n        <span class=\"sidebar-topic-check\">\n            "
    + alias4((((helper = lookupProperty(helpers,"topic_resolved_prefix") || alias2(depth0, "topic_resolved_prefix", {"start":{"line":4,"column":14},"end":{"line":4,"column":35}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_resolved_prefix","hash":{},"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":4,"column":37}}}) : helper)))
    + "\n        </span>\n        <a href=\""
    + alias4((((helper = lookupProperty(helpers,"url") || alias2(depth0, "url", {"start":{"line":6,"column":19},"end":{"line":6,"column":22}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data,"loc":{"start":{"line":6,"column":17},"end":{"line":6,"column":24}}}) : helper)))
    + "\" class=\"topic-name\" title=\""
    + alias4((((helper = lookupProperty(helpers,"topic_name") || alias2(depth0, "topic_name", {"start":{"line":6,"column":54},"end":{"line":6,"column":64}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":6,"column":52},"end":{"line":6,"column":66}}}) : helper)))
    + "\">\n            "
    + alias4((((helper = lookupProperty(helpers,"topic_display_name") || alias2(depth0, "topic_display_name", {"start":{"line":7,"column":14},"end":{"line":7,"column":32}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"topic_display_name","hash":{},"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":7,"column":34}}}) : helper)))
    + "\n        </a>\n        <div class=\"topic-markers-and-controls\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"contains_unread_mention"),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":16,"column":19}}})) != null ? stack1 : "")
    + "            <span class=\"unread_count "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_zero"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":70}}})) != null ? stack1 : "")
    + "\">\n                "
    + alias4((((helper = lookupProperty(helpers,"unread") || alias2(depth0, "unread", {"start":{"line":18,"column":18},"end":{"line":18,"column":24}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"unread","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":26}}}) : helper)))
    + "\n            </span>\n        </div>\n        <span class=\"sidebar-menu-icon topic-sidebar-menu-icon\">\n            <i class=\"zulip-icon zulip-icon-more-vertical\" aria-hidden=\"true\"></i>\n        </span>\n    </div>\n</li>\n";
},"useData":true});