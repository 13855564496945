var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <button type=\"submit\" id=\"show-add-default-streams-modal\" class=\"button rounded sea-green\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Add stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":107},"end":{"line":8,"column":126}}}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":36},"end":{"line":19,"column":52}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"admin-default-streams-list\" class=\"settings-section\" data-name=\"default-streams-list\">\n    <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure the default streams new users are subscribed to when joining your organization.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":7},"end":{"line":2,"column":105}}}))
    + "</p>\n\n    <div class=\"settings_panel_list_header\">\n        <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Default streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":35}}}))
    + "</h3>\n        <div class=\"add_default_streams_button_container\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":12},"end":{"line":9,"column":19}}})) != null ? stack1 : "")
    + "            <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter default streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":77},"end":{"line":10,"column":108}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter streams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":122},"end":{"line":10,"column":145}}}))
    + "\"/>\n        </div>\n    </div>\n\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th class=\"active\" data-sort=\"alphabetic\" data-sort-prop=\"name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":80},"end":{"line":17,"column":93}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":20,"column":23}}})) != null ? stack1 : "")
    + "            </thead>\n            <tbody data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"There are no default streams.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":31},"end":{"line":22,"column":69}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No default streams match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":98},"end":{"line":22,"column":152}}}))
    + "\"\n              id=\"admin_default_streams_table\" class=\"admin_default_stream_table\"></tbody>\n        </table>\n    </div>\n\n    <div id=\"admin_page_default_streams_loading_indicator\"></div>\n</div>\n";
},"useData":true});