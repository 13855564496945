var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":3},"end":{"line":2,"column":67}}})) != null ? stack1 : "")
    + " to:</p>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "Move all messages in <strong>{topic_name}</strong>";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Rename topic to:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":7},"end":{"line":6,"column":32}}}))
    + "</p>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <p>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Select a stream below or change topic name.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":7},"end":{"line":8,"column":59}}}))
    + "</p>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = container.invokePartial(require("./dropdown_widget_wrapper.hbs"),depth0,{"name":"dropdown_widget_wrapper","hash":{"widget_name":"move_topic_to_stream"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <i class=\"fa fa-angle-right\" aria-hidden=\"true\"></i>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <select class=\"message_edit_topic_propagate modal_select bootstrap-focus-style\">\n            <option value=\"change_one\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_placement"),"last",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":20,"column":45},"end":{"line":20,"column":74}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":39},"end":{"line":20,"column":91}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move only this message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":93},"end":{"line":20,"column":124}}}))
    + "</option>\n            <option value=\"change_later\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_placement"),"intermediate",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":21,"column":47},"end":{"line":21,"column":84}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":41},"end":{"line":21,"column":101}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move this and all following messages in this topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":103},"end":{"line":21,"column":162}}}))
    + "</option>\n            <option value=\"change_all\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"eq").call(alias1,lookupProperty(depth0,"message_placement"),"first",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":22,"column":45},"end":{"line":22,"column":75}}}),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":39},"end":{"line":22,"column":92}}})) != null ? stack1 : "")
    + "> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Move all messages in this topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":94},"end":{"line":22,"column":134}}}))
    + "</option>\n        </select>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "selected";
},"15":function(container,depth0,helpers,partials,data) {
    return "checked=\"checked\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3=container.lambda, alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"from_message_actions_popover"),lookupProperty(depth0,"only_topic_edit"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":59}}}),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":3,"column":11}}})) != null ? stack1 : "")
    + "<form class=\"new-style\" id=\"move_topic_form\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"only_topic_edit"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"topic_stream_edit_header\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"only_topic_edit"),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":8},"end":{"line":14,"column":19}}})) != null ? stack1 : "")
    + "        <input name=\"new_topic_name\" type=\"text\" class=\"move_messages_edit_topic modal_text_input\" autocomplete=\"off\" value=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":15,"column":127},"end":{"line":15,"column":137}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"disable_topic_input"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":141},"end":{"line":15,"column":183}}})) != null ? stack1 : "")
    + " />\n        <input name=\"old_topic_name\" type=\"hidden\" class=\"move_messages_edit_topic\" value=\""
    + alias4(alias3(alias2(depth0, "topic_name", {"start":{"line":16,"column":93},"end":{"line":16,"column":103}} ), depth0))
    + "\" />\n        <input name=\"current_stream_id\" type=\"hidden\" value=\""
    + alias4(alias3(alias2(depth0, "current_stream_id", {"start":{"line":17,"column":63},"end":{"line":17,"column":80}} ), depth0))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"from_message_actions_popover"),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "        <div class=\"topic_move_breadcrumb_messages\">\n            <label class=\"checkbox\">\n                <input class=\"send_notification_to_new_thread\" name=\"send_notification_to_new_thread\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"notify_new_thread"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":118},"end":{"line":27,"column":167}}})) != null ? stack1 : "")
    + " />\n                <span></span>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Send automated notice to new topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":29,"column":59}}}))
    + "\n            </label>\n            <label class=\"checkbox\">\n                <input class=\"send_notification_to_old_thread\" name=\"send_notification_to_old_thread\" type=\"checkbox\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"notify_old_thread"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":118},"end":{"line":32,"column":167}}})) != null ? stack1 : "")
    + " />\n                <span></span>\n                "
    + alias4(lookupProperty(helpers,"t").call(alias1,"Send automated notice to old topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":16},"end":{"line":34,"column":59}}}))
    + "\n            </label>\n        </div>\n    </div>\n</form>\n";
},"usePartial":true,"useData":true});