var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"subscriber_list_settings\">\n    <div class=\"subscriber_list_add float-left\">\n        "
    + ((stack1 = container.invokePartial(require("./add_subscribers_form.hbs"),depth0,{"name":"add_subscribers_form","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <br />\n\n\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Do you want to add everyone?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":40}}}))
    + "\n    <span class=\"add_all_users_to_stream_btn_container\">\n        <button class=\"add_all_users_to_stream small button rounded sea-green\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add all users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":79},"end":{"line":10,"column":100}}}))
    + "</button>\n    </span>\n</div>\n\n<div class=\"create_stream_subscriber_list_header\">\n    <h4 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":48},"end":{"line":15,"column":68}}}))
    + "</h4>\n    <input class=\"add-user-list-filter filter_text_input\" name=\"user_list_filter\" type=\"text\"\n      autocomplete=\"off\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":38},"end":{"line":17,"column":65}}}))
    + "\" />\n</div>\n\n<div class=\"subscriber-list-box\">\n    <div class=\"subscriber_list_container\" data-simplebar>\n        <table class=\"subscriber-list table table-striped\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"full_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":70},"end":{"line":24,"column":83}}}))
    + "</th>\n                <th data-sort=\"email\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":38},"end":{"line":25,"column":52}}}))
    + "</th>\n                <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Action",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":35}}}))
    + "</th>\n            </thead>\n            <tbody id=\"create_stream_subscribers\" class=\"subscriber_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"This stream has no subscribers.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":87},"end":{"line":28,"column":127}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No stream subscribers match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":156},"end":{"line":28,"column":212}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});