var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"settings-radio-input-parent\">\n                <label class=\"radio\">\n                    <input type=\"radio\" name=\"privacy\" value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":11,"column":65},"end":{"line":11,"column":74}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),lookupProperty(depths[1],"stream_privacy_policy"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":11,"column":85},"end":{"line":11,"column":124}}}),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":79},"end":{"line":11,"column":141}}})) != null ? stack1 : "")
    + " />\n                    <b>"
    + alias3(alias2(alias1(depth0, "name", {"start":{"line":12,"column":26},"end":{"line":12,"column":35}} ), depth0))
    + ":</b> "
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":12,"column":47},"end":{"line":12,"column":63}} ), depth0))
    + "\n                </label>\n            </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "checked";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, alias4=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <option value=\""
    + alias3(alias2(alias1(depth0, "code", {"start":{"line":35,"column":29},"end":{"line":35,"column":38}} ), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias4,lookupProperty(helpers,"eq").call(alias4,lookupProperty(depth0,"code"),lookupProperty(depths[1],"stream_post_policy"),{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":35,"column":48},"end":{"line":35,"column":84}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":42},"end":{"line":35,"column":102}}})) != null ? stack1 : "")
    + ">\n                "
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":36,"column":19},"end":{"line":36,"column":35}} ), depth0))
    + "\n            </option>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "selected";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <div class=\"input-group inline-block message-retention-setting-group time-limit-setting\">\n        <label class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Message retention period",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":50,"column":38},"end":{"line":50,"column":71}}}))
    + "\n            "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/message-retention-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </label>\n\n        "
    + ((stack1 = container.invokePartial(require("../settings/upgrade_tip_widget.hbs"),depth0,{"name":"../settings/upgrade_tip_widget","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <select name=\"stream_message_retention_setting\"\n          class=\"stream_message_retention_setting prop-element settings_select bootstrap-focus-style\"\n          id=\"id_message_retention_days\"\n          data-setting-widget-type=\"message-retention-setting\">\n            <option value=\"realm_default\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Use organization level settings {org_level_message_retention_setting}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":42},"end":{"line":60,"column":120}}}))
    + "</option>\n            <option value=\"unlimited\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retain forever",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":61,"column":38},"end":{"line":61,"column":61}}}))
    + "</option>\n            <option value=\"custom_period\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Custom",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":42},"end":{"line":62,"column":57}}}))
    + "</option>\n        </select>\n\n        <div class=\"dependent-settings-block stream-message-retention-days-input\">\n            <label class=\"inline-block\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Retention period (days)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":67,"column":48}}}))
    + ":\n            </label>\n            <input type=\"text\" autocomplete=\"off\"\n              name=\"stream-message-retention-days\"\n              class=\"stream-message-retention-days message-retention-setting-custom-input time-limit-custom-input\"\n              id=\"stream_message_retention_custom_input\" />\n        </div>\n    </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"input-group stream-privacy-values\">\n    <div class=\"alert stream-privacy-status\"></div>\n    <label>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can access the stream?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":11},"end":{"line":3,"column":45}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/stream-permissions"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </label>\n\n    <div class=\"stream-privacy_choices prop-element\" id=\"id_stream_privacy\" data-setting-widget-type=\"radio-group\" data-setting-choice-type=\"string\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"stream_privacy_policy_values"),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":8},"end":{"line":15,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n<div class=\"default-stream\">\n    "
    + ((stack1 = container.invokePartial(require("../settings/settings_checkbox.hbs"),depth0,{"name":"../settings/settings_checkbox","hash":{"help_link":"/help/set-default-streams-for-new-users","label":"Default stream for new users","is_checked":lookupProperty(depth0,"check_default_stream"),"setting_name":"is_default_stream","prefix":"id_"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"input-group\">\n    <label class=\"dropdown-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Who can post to the stream?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":34},"end":{"line":30,"column":69}}}))
    + "\n        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/stream-sending-policy"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </label>\n    <select name=\"stream-post-policy\" class=\"stream_post_policy_setting prop-element settings_select bootstrap-focus-style\" id=\"id_stream_post_policy\" data-setting-widget-type=\"number\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"stream_post_policy_values"),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":38,"column":17}}})) != null ? stack1 : "")
    + "    </select>\n</div>\n\n"
    + ((stack1 = container.invokePartial(require("../dropdown_widget_with_label.hbs"),depth0,{"name":"../dropdown_widget_with_label","hash":{"value_type":"number","label":lookupProperty(helpers,"t").call(alias1,"Who can unsubscribe others from this stream?",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":44,"column":8},"end":{"line":44,"column":58}}}),"widget_name":lookupProperty(depth0,"can_remove_subscribers_setting_widget_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"is_owner"),lookupProperty(depth0,"is_stream_edit"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":47,"column":6},"end":{"line":47,"column":34}}}),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":0},"end":{"line":76,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});