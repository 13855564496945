var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hide";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dropdown-list-container "
    + alias2((((helper = lookupProperty(helpers,"widget_name") || container.strict(depth0, "widget_name", {"start":{"line":1,"column":38},"end":{"line":1,"column":49}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"widget_name","hash":{},"data":data,"loc":{"start":{"line":1,"column":36},"end":{"line":1,"column":51}}}) : helper)))
    + "-dropdown-list-container\">\n    <div class=\"dropdown-list-search\">\n        <input class=\"dropdown-list-search-input filter_text_input"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"hide_search_box"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":66},"end":{"line":3,"column":101}}})) != null ? stack1 : "")
    + "\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":128},"end":{"line":3,"column":143}}}))
    + "\" autofocus/>\n    </div>\n    <div class=\"dropdown-list-wrapper\" data-simplebar>\n        <ul class=\"dropdown-list\"></ul>\n    </div>\n    <div class=\"no-dropdown-items dropdown-list-item-common-styles\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"No matching results",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":35}}}))
    + "\n    </div>\n</div>\n";
},"useData":true});