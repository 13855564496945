var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div id=\"announce-new-stream\">\n                        "
    + ((stack1 = container.invokePartial(require("./announce_stream_checkbox.hbs"),depth0,{"name":"announce_stream_checkbox","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"hide\" id=\"stream-creation\" tabindex=\"-1\" role=\"dialog\"\n  aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream creation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":14},"end":{"line":2,"column":38}}}))
    + "\">\n    <form id=\"stream_creation_form\">\n        <div class=\"stream-creation-simplebar-container\" data-simplebar>\n            <div class=\"alert stream_create_info\"></div>\n            <div id=\"stream_creating_indicator\"></div>\n            <div class=\"stream-creation-body\">\n                <section class=\"block\">\n                    <label for=\"create_stream_name\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":24},"end":{"line":10,"column":44}}}))
    + "\n                    </label>\n                    <input type=\"text\" name=\"stream_name\" id=\"create_stream_name\" class=\"settings_text_input\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":35},"end":{"line":13,"column":55}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_stream_name_length", {"start":{"line":13,"column":99},"end":{"line":13,"column":121}} ), depth0))
    + "\" />\n                    <div id=\"stream_name_error\" class=\"stream_creation_error\"></div>\n                </section>\n                <section class=\"block\">\n                    <label for=\"create_stream_description\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":51}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/change-the-stream-description"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </label>\n                    <input type=\"text\" name=\"stream_description\" id=\"create_stream_description\" class=\"settings_text_input\"\n                      placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":35},"end":{"line":22,"column":62}}}))
    + "\" value=\"\" autocomplete=\"off\" maxlength=\""
    + alias2(alias4(alias3(depth0, "max_stream_description_length", {"start":{"line":22,"column":106},"end":{"line":22,"column":135}} ), depth0))
    + "\" />\n                </section>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"ask_to_announce_stream"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + "                <section class=\"block\" id=\"make-invite-only\">\n                    <div class=\"stream-types\">\n                        <h3 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream permissions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":68},"end":{"line":31,"column":95}}}))
    + "</h3>\n                        "
    + ((stack1 = container.invokePartial(require("./stream_types.hbs"),depth0,{"name":"stream_types","hash":{"can_remove_subscribers_setting_widget_name":"new_stream_can_remove_subscribers_group","is_stream_edit":false,"stream_post_policy":lookupProperty(lookupProperty(lookupProperty(depth0,"stream_post_policy_values"),"everyone"),"code")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </section>\n                <section class=\"block\">\n                    <label for=\"people_to_add\">\n                        <h4 class=\"stream_setting_subsection_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Choose subscribers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":68},"end":{"line":40,"column":95}}}))
    + "</h4>\n                    </label>\n                    <div id=\"stream_subscription_error\" class=\"stream_creation_error\"></div>\n                    <div class=\"controls\" id=\"people_to_add\"></div>\n                </section>\n            </div>\n        </div>\n        <div class=\"settings-sticky-footer\">\n            <button class=\"button small white rounded\" data-dismiss=\"modal\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":76},"end":{"line":48,"column":91}}}))
    + "</button>\n            <button class=\"finalize_create_stream button small sea-green rounded\" type=\"submit\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Create",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":49,"column":96},"end":{"line":49,"column":111}}}))
    + "</button>\n        </div>\n    </form>\n</div>\n";
},"usePartial":true,"useData":true});