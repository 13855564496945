var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <td class=\"subscriber-email\">"
    + container.escapeExpression(container.lambda(container.strict(depth0, "email", {"start":{"line":6,"column":39},"end":{"line":6,"column":44}} ), depth0))
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <td class=\"hidden-subscriber-email\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"(hidden)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":44},"end":{"line":8,"column":60}}}))
    + "</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"panel_user_list\">\n        "
    + ((stack1 = container.invokePartial(require("../user_display_only_pill.hbs"),depth0,{"name":"../user_display_only_pill","hash":{"display_value":lookupProperty(depth0,"full_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"email"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "    <td>\n        <button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"disabled"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":16},"end":{"line":11,"column":59}}})) != null ? stack1 : "")
    + " data-user-id=\""
    + alias2(container.lambda(container.strict(depth0, "user_id", {"start":{"line":11,"column":76},"end":{"line":11,"column":83}} ), depth0))
    + "\" class=\"remove_potential_subscriber button small rounded white\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Remove",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":150},"end":{"line":11,"column":165}}}))
    + "</button>\n    </td>\n</tr>\n";
},"usePartial":true,"useData":true});