var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <li class=\"condensed-views-popover-menu-scheduled-messages\">\n        <a tabindex=\"0\" href=\"#scheduled\" class=\"left-sidebar-popover-icon-label-count\">\n            <span class=\"filter-icon\">\n                <i class=\"zulip-icon zulip-icon-scheduled-messages\" aria-hidden=\"true\"></i>\n            </span>\n            <span class=\"left-sidebar-navigation-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Scheduled messages",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":56},"end":{"line":19,"column":83}}}))
    + "</span>\n            <span class=\"unread_count\"></span>\n        </a>\n    </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav nav-list condensed-views-popover-menu\">\n    <li class=\"condensed-views-popover-menu-drafts\">\n        <a tabindex=\"0\" href=\"#drafts\" class=\"left-sidebar-popover-icon-label-count tippy-left-sidebar-tooltip\" data-tooltip-template-id=\"drafts-tooltip-template\">\n            <span class=\"filter-icon\">\n                <i class=\"zulip-icon zulip-icon-drafts\" aria-hidden=\"true\"></i>\n            </span>\n            <span class=\"left-sidebar-navigation-label\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(alias1,"Drafts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":56},"end":{"line":9,"column":71}}}))
    + "</span>\n            <span class=\"unread_count\"></span>\n        </a>\n    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"has_scheduled_messages"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});