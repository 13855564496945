var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"popover-menu-outer-list-item\">\n            <ul class=\"popover-menu-inner-list\">\n                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":8,"column":42},"end":{"line":8,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":54}}}) : helper)))
    + "\" class=\"respond_button popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-quote-and-reply\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Quote and reply",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":57},"end":{"line":10,"column":81}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,">",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":52}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n        </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"popover-menu-outer-list-item\">\n            <ul class=\"popover-menu-inner-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"editability_menu_item"),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"move_message_menu_item"),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":37,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_delete_option"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias1(depth0, "message_id", {"start":{"line":22,"column":42},"end":{"line":22,"column":52}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":22,"column":40},"end":{"line":22,"column":54}}}) : helper)))
    + "\" class=\"popover_edit_message popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-edit\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4((((helper = lookupProperty(helpers,"editability_menu_item") || alias1(depth0, "editability_menu_item", {"start":{"line":24,"column":59},"end":{"line":24,"column":80}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"editability_menu_item","hash":{},"data":data,"loc":{"start":{"line":24,"column":57},"end":{"line":24,"column":82}}}) : helper)))
    + "</span>\n                        "
    + alias4(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"E",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":52}}}))
    + "\n                    </a>\n                </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias1(depth0, "message_id", {"start":{"line":31,"column":42},"end":{"line":31,"column":52}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":31,"column":40},"end":{"line":31,"column":54}}}) : helper)))
    + "\" class=\"popover_move_message popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-move-alt\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4((((helper = lookupProperty(helpers,"move_message_menu_item") || alias1(depth0, "move_message_menu_item", {"start":{"line":33,"column":59},"end":{"line":33,"column":81}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"move_message_menu_item","hash":{},"data":data,"loc":{"start":{"line":33,"column":57},"end":{"line":33,"column":83}}}) : helper)))
    + "</span>\n                        "
    + alias4(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"M",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":34,"column":24},"end":{"line":34,"column":52}}}))
    + "\n                    </a>\n                </li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":40,"column":42},"end":{"line":40,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":40,"column":40},"end":{"line":40,"column":54}}}) : helper)))
    + "\" class=\"delete_message popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-trash\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delete message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":57},"end":{"line":42,"column":80}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"popover-menu-outer-list-item\">\n            <ul class=\"popover-menu-inner-list\">\n                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":53,"column":42},"end":{"line":53,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":53,"column":40},"end":{"line":53,"column":54}}}) : helper)))
    + "\" class=\"reaction_button popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-smile\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add emoji reaction",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":55,"column":57},"end":{"line":55,"column":84}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,":",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":56,"column":24},"end":{"line":56,"column":52}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n        </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"popover-menu-outer-list-item\">\n            <ul class=\"popover-menu-inner-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":73,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_hide_option"),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":16},"end":{"line":81,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_collapse"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":90,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_uncollapse"),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":16},"end":{"line":99,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":67,"column":42},"end":{"line":67,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":67,"column":40},"end":{"line":67,"column":54}}}) : helper)))
    + "\" class=\"mark_as_unread popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-mark-as-unread\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mark as unread from here",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":57},"end":{"line":69,"column":90}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,"Shift","U",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":70,"column":24},"end":{"line":70,"column":60}}}))
    + "\n                    </a>\n                </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":76,"column":42},"end":{"line":76,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":76,"column":40},"end":{"line":76,"column":54}}}) : helper)))
    + "\" class=\"rehide_muted_user_message popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-hide\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Hide muted message again",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":57},"end":{"line":78,"column":90}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":84,"column":42},"end":{"line":84,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":84,"column":40},"end":{"line":84,"column":54}}}) : helper)))
    + "\" class=\"popover_toggle_collapse popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-collapse\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Collapse message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":86,"column":57},"end":{"line":86,"column":82}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,"-",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":87,"column":24},"end":{"line":87,"column":52}}}))
    + "\n                    </a>\n                </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":93,"column":42},"end":{"line":93,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":93,"column":40},"end":{"line":93,"column":54}}}) : helper)))
    + "\" class=\"popover_toggle_collapse popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-expand\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Expand message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":57},"end":{"line":95,"column":80}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,"-",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":96,"column":24},"end":{"line":96,"column":52}}}))
    + "\n                    </a>\n                </li>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias1(depth0, "message_id", {"start":{"line":107,"column":42},"end":{"line":107,"column":52}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":107,"column":40},"end":{"line":107,"column":54}}}) : helper)))
    + "\" class=\"popover_view_source popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-source\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4((((helper = lookupProperty(helpers,"view_source_menu_item") || alias1(depth0, "view_source_menu_item", {"start":{"line":109,"column":59},"end":{"line":109,"column":80}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"view_source_menu_item","hash":{},"data":data,"loc":{"start":{"line":109,"column":57},"end":{"line":109,"column":82}}}) : helper)))
    + "</span>\n                        "
    + alias4(lookupProperty(helpers,"popover_hotkey_hints").call(alias2,"E",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":110,"column":24},"end":{"line":110,"column":52}}}))
    + "\n                    </a>\n                </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a data-message-id=\""
    + alias2((((helper = lookupProperty(helpers,"message_id") || container.strict(depth0, "message_id", {"start":{"line":116,"column":42},"end":{"line":116,"column":52}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":116,"column":40},"end":{"line":116,"column":54}}}) : helper)))
    + "\" class=\"view_read_receipts popover-menu-link\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-readreceipts\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"View read receipts",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":118,"column":57},"end":{"line":118,"column":84}}}))
    + "</span>\n                        "
    + alias2(lookupProperty(helpers,"popover_hotkey_hints").call(alias1,"Shift","V",{"name":"popover_hotkey_hints","hash":{},"data":data,"loc":{"start":{"line":119,"column":24},"end":{"line":119,"column":60}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"popover-menu\" id=\"message-actions-menu-dropdown\" data-simplebar>\n    <ul class=\"popover-menu-outer-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_quote_and_reply"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":8},"end":{"line":16,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"editability_menu_item"),lookupProperty(depth0,"move_message_menu_item"),lookupProperty(depth0,"should_display_delete_option"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":92}}}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":8},"end":{"line":48,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_add_reaction_option"),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":8},"end":{"line":61,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"should_display_mark_as_unread"),lookupProperty(depth0,"should_display_reminder_option"),lookupProperty(depth0,"should_display_hide_option"),lookupProperty(depth0,"should_display_collapse"),lookupProperty(depth0,"should_display_uncollapse"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":62,"column":14},"end":{"line":62,"column":156}}}),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":8},"end":{"line":102,"column":15}}})) != null ? stack1 : "")
    + "        <li class=\"popover-menu-outer-list-item\">\n            <ul class=\"popover-menu-inner-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"view_source_menu_item"),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":16},"end":{"line":113,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"should_display_read_receipts_option"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":16},"end":{"line":122,"column":23}}})) != null ? stack1 : "")
    + "                <li class=\"link-item popover-menu-inner-list-item\">\n                    <a class=\"copy_link navigate-link-on-enter popover-menu-link\" data-message-id=\""
    + alias4((((helper = lookupProperty(helpers,"message_id") || alias2(depth0, "message_id", {"start":{"line":124,"column":101},"end":{"line":124,"column":111}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"message_id","hash":{},"data":data,"loc":{"start":{"line":124,"column":99},"end":{"line":124,"column":113}}}) : helper)))
    + "\" data-clipboard-text=\""
    + alias4((((helper = lookupProperty(helpers,"conversation_time_url") || alias2(depth0, "conversation_time_url", {"start":{"line":124,"column":139},"end":{"line":124,"column":160}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"conversation_time_url","hash":{},"data":data,"loc":{"start":{"line":124,"column":136},"end":{"line":124,"column":163}}}) : helper)))
    + "\" tabindex=\"0\">\n                        <i class=\"popover-menu-icon zulip-icon zulip-icon-link-alt\" aria-hidden=\"true\"></i>\n                        <span class=\"popover-menu-label\">"
    + alias4(lookupProperty(helpers,"t").call(alias1,"Copy link to message",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":57},"end":{"line":126,"column":86}}}))
    + "</span>\n                    </a>\n                </li>\n            </ul>\n        </li>\n    </ul>\n</div>\n";
},"useData":true});