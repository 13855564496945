var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <label for=\"change_user_group_name\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":32}}}))
    + "\n    </label>\n    <input type=\"text\" id=\"change_user_group_name\" class=\"modal_text_input\" name=\"user_group_name\" value=\""
    + alias2((((helper = lookupProperty(helpers,"group_name") || alias3(depth0, "group_name", {"start":{"line":5,"column":109},"end":{"line":5,"column":119}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"group_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":106},"end":{"line":5,"column":122}}}) : helper)))
    + "\" maxlength=\""
    + alias2((((helper = lookupProperty(helpers,"max_user_group_name_length") || alias3(depth0, "max_user_group_name_length", {"start":{"line":5,"column":137},"end":{"line":5,"column":163}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"max_user_group_name_length","hash":{},"data":data,"loc":{"start":{"line":5,"column":135},"end":{"line":5,"column":165}}}) : helper)))
    + "\" />\n</div>\n<div>\n    <label for=\"change_user_group_description\">\n        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"User group description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":8},"end":{"line":9,"column":39}}}))
    + "\n    </label>\n    <textarea id=\"change_user_group_description\" class=\"settings_textarea\" name=\"user_group_description\">"
    + alias2((((helper = lookupProperty(helpers,"group_description") || alias3(depth0, "group_description", {"start":{"line":11,"column":108},"end":{"line":11,"column":125}} ))),(typeof helper === alias4 ? helper.call(alias1,{"name":"group_description","hash":{},"data":data,"loc":{"start":{"line":11,"column":105},"end":{"line":11,"column":128}}}) : helper)))
    + "</textarea>\n</div>\n";
},"useData":true});