var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                For more examples, see the <z-link>help center documentation</z-link>\n                on adding linkifiers.\n                \n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/add-a-custom-linkifier\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <form class=\"admin-linkifier-form\">\n            <div class=\"add-new-linkifier-box grey-box\">\n                <div class=\"new-linkifier-form wrapper\">\n                    <div class=\"settings-section-title new-linkifier-section-title\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add a new linkifier",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":24},"end":{"line":37,"column":52}}}))
    + "\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/add-a-custom-linkifier"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                    <div class=\"alert\" id=\"admin-linkifier-status\"></div>\n                    <div class=\"input-group\">\n                        <label for=\"linkifier_pattern\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":55},"end":{"line":42,"column":71}}}))
    + "</label>\n                        <input type=\"text\" id=\"linkifier_pattern\" class=\"settings_text_input\" name=\"pattern\" placeholder=\"#(?P<id>[0-9]+)\" />\n                        <div class=\"alert\" id=\"admin-linkifier-pattern-status\"></div>\n                    </div>\n                    <div class=\"input-group\">\n                        <label for=\"linkifier_template\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":56},"end":{"line":47,"column":77}}}))
    + "</label>\n                        <input type=\"text\" id=\"linkifier_template\" class=\"settings_text_input\" name=\"url_template\" placeholder=\"https://github.com/zulip/zulip/issues/{id}\" />\n                        <div class=\"alert\" id=\"admin-linkifier-template-status\"></div>\n                    </div>\n                    <button type=\"submit\" class=\"button rounded sea-green\">\n                        "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Add linkifier",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":52,"column":24},"end":{"line":52,"column":46}}}))
    + "\n                    </button>\n                </div>\n            </div>\n        </form>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <th class=\"actions\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Actions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":40},"end":{"line":71,"column":56}}}))
    + "</th>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"linkifier-settings\" class=\"settings-section\" data-name=\"linkifier-settings\">\n    <div class=\"admin-table-wrapper\">\n\n        <p>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure regular expression patterns that will be used to\n              automatically transform any matching text in Zulip messages\n              and topics into links.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":7,"column":40}}}))
    + "\n        </p>\n        <p>\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers make it easy to refer to issues or tickets in\n              third party issue trackers, like GitHub, Salesforce, Zendesk,\n              and others. For instance, you can add a linkifier that\n              automatically turns #2468 into a link to the GitHub issue\n              in the Zulip repository with:",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":14,"column":47}}}))
    + "\n        </p>\n        <ul>\n            <li>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":32}}}))
    + ": <span class=\"rendered_markdown\"><code>#(?P&lt;id&gt;[0-9]+)</code></span>\n            </li>\n            <li>\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":37}}}))
    + ": <span class=\"rendered_markdown\"><code>https://github.com/zulip/zulip/issues/{id}</code></span>\n            </li>\n        </ul>\n        <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":29,"column":19}}})) != null ? stack1 : "")
    + "        </p>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":57,"column":15}}})) != null ? stack1 : "")
    + "\n        <div class=\"settings_panel_list_header\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":60,"column":16},"end":{"line":60,"column":34}}}))
    + "</h3>\n            <div class=\"alert-notification edit-linkifier-status\" id=\"linkifier-field-status\"></div>\n            <input type=\"text\" class=\"search filter_text_input\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":77},"end":{"line":62,"column":103}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter linkifiers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":62,"column":117},"end":{"line":62,"column":143}}}))
    + "\"/>\n        </div>\n\n        <div class=\"progressive-table-wrapper\" data-simplebar>\n            <table class=\"table table-striped wrapped-table admin_linkifiers_table\">\n                <thead class=\"table-sticky-headers\">\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Pattern",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":24},"end":{"line":68,"column":40}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"URL template",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":24},"end":{"line":69,"column":45}}}))
    + "</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_admin"),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":20},"end":{"line":72,"column":27}}})) != null ? stack1 : "")
    + "                </thead>\n                <tbody id=\"admin_linkifiers_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No linkifiers configured.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":63},"end":{"line":74,"column":97}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No linkifiers match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":126},"end":{"line":74,"column":175}}}))
    + "\"></tbody>\n            </table>\n        </div>\n    </div>\n</div>\n";
},"1_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":153}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});